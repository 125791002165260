import * as _ from 'lodash';
import { ElementType, IContentElement, IQuestionConfig } from "src/app/ui-testrunner/models";
import { ItemSetFrameworkCtrl } from "../../item-set-editor/controllers/framework";
import { ItemBankCtrl } from "../../item-set-editor/controllers/item-bank";
import { IAuditConfig, IAuditPatch } from '../data/audits';
import { getQuestionDeepElements } from './content-analysis';



interface IContext {
    itemBankCtrl: ItemBankCtrl, // todo: highly stateful
    frameworkCtrl: ItemSetFrameworkCtrl, // todo: highly stateful
}

export const processPatch = async (audit:IAuditConfig, patch:IAuditPatch, question: IQuestionConfig, ctx:IContext) => {
  // todo: limit to flagged questions by making the fix a child of the check
  // const flaggedQuestions = auditResultsMap.get(check.id).items; //
  switch (patch.fixType){
    case 'BY_BLOCK_TYPE': return processPatchByBlockType( audit, patch, question, ctx);
  }
}

const processPatchByBlockType = async (audit:IAuditConfig, patch:IAuditPatch, qConfig: IQuestionConfig, ctx:IContext) => {
  for (let fixConfig of patch.fixConfigs){
    const { targetBlockType, propPath, val } = fixConfig;
    const blockElements = getQuestionDeepElements(qConfig);
    for (let el of blockElements){
      if (targetBlockType == el.elementType){
        const valOld = _.set(el, propPath);
        let valNew = val;
        // todo: could run transforms against the old val to get alternative, at that point, should make the direct override just one type of transformation
        _.set(el, propPath, valNew);
      }
    }
  }
}