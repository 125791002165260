import { FormControl } from '@angular/forms';
import DeepDiff from 'deep-diff';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthRolesService } from '../../auth-roles.service';
import { AuthScopeSetting, AuthScopeSettingsService } from "../../auth-scope-settings.service";
import { AuthService } from '../../../api/auth.service';
import { EditingDisabledService } from '../../editing-disabled.service';
import { extractQuestionAssetVersionIds, updateCurrentQuestionExpectedAnswer } from '../models/expected-answer';
import { FRAMEWORK_CONFIG } from '../models/framework-data';
import { getElementChildren } from '../models';
import { IAssessmentFrameworkDef, QUESTION_WORDING_OPTS } from '../models/assessment-framework';
import { IContentElement, IHistoricalItemRegisterModel, IHistoricalItemRegisterModelTw, IItemRegister, IItemRegisterByItemTd, IItemRegisterSummary, IQuestionScoringInfo, IScoringCodes, ISequenceConfig } from '../../../ui-testrunner/models/index';
import { ignoreFromDiff, ItemComponentEditService } from '../../item-component-edit.service';
import { IItemTag } from '../../item-tag/item-tag.component';
import { IItemSetResponse, IQuestionConfig, IQuestionImpressionConfig } from '../../../ui-testrunner/models';
import { ItemBankCtrl } from './item-bank';
import { ItemDiffCtrl } from './item-diff';
import { ItemEditCtrl } from './item-edit';
import { ItemFilterCtrl } from './item-filter';
import { ItemMakerService } from '../../item-maker.service';
import { ItemSetFrameworkCtrl } from './framework';
import { ItemType } from '../../models';
import { LangService } from '../../../core/lang.service';
import { MemberAssignmentCtrl } from './member-assignment';
import { RoutesService } from '../../../api/routes.service';
import { serverTimestamp } from '../models/task-types';
import { Subscription } from 'rxjs';
import { EStyleProfile } from 'src/app/core/styleprofile.service';
import { UserRoles } from 'src/app/api/models/roles';
import { Destroyable } from './destroyable';
import { refreshScoreMatrix } from "./../../config-score-matrix/models"
import { deepFind } from "./../../services/util"

export class ItemBankSaveLoadCtrl implements Destroyable {

  currentHash: string;
  isSaving: boolean;
  isSuggesting: boolean;
  isLoading = true;
  isLoadingQuestion;
  isUserInited = false;
  isSaveChangedRequired = false;
  existingFakeSaveDelayTimeout;
  public isInspecting: boolean;
  public itemBanksUsed = [];
  isSavingItemSet:boolean;
  isLoadingItemSets;

  restoreENFc = new FormControl(true);
  restoreFRFc = new FormControl(false);
  restoreParamsFc = new FormControl(true);
  restoreLabel = new FormControl(false);

  importEN = new FormControl(true);
  importFR = new FormControl(false);
  importLabel = new FormControl(false);
  importParams = new FormControl(true);

  public itemFilterCtrl: ItemFilterCtrl;

  currentQuestionRevisions: {
    displayList?: any[],
    originalQuestion?: string; // IQuestionConfig
    restoredToId?: number, 
    restoringToId?: number, 
    skippedOptions?: {skipEN: boolean, skipFR: boolean, skipParams: boolean, skipLabel: boolean} 
  };
  isLoadingRevisions: boolean;
  isLoadingQuestionVersion: boolean;
  isLoadingVersionId: number = null;
  private saveInterval;
  saveConflictWarningGiven: boolean;
  userSub:Subscription
  autoSaveSub: Subject<any> = new Subject();

  public itemEditCtrl:ItemEditCtrl;
  public itemDiffCtrl: ItemDiffCtrl;

  public setLoaderEvent:BehaviorSubject<boolean> = new BehaviorSubject(false);

  
  constructor(
    public auth: AuthService,
    public myItems: ItemMakerService,
    public authScopeSettings: AuthScopeSettingsService,
    public routes: RoutesService,
    public editingDisabled: EditingDisabledService,
    public authRoles: AuthRolesService,
    public memberAssignmentCtrl:MemberAssignmentCtrl,
    public frameworkCtrl: ItemSetFrameworkCtrl,
    public itemBankCtrl:ItemBankCtrl,
    public itemComponentEdit: ItemComponentEditService,
    public lang: LangService
  ){
    this.initSaveInterval();
    this.itemComponentEdit.saveCurrQSub.subscribe(() => {
      this.saveCurrentQuestionData(true);
    })
  }

  destroy(){
    if (this.saveInterval) {
      clearInterval(this.saveInterval);
    }
    this.userSub.unsubscribe();
  }

  initSaveInterval(){
    this.saveInterval = setInterval(() => {
      this.autoSaveQuestionData();
    }, 30 * 1000);
  }

  processHistoricalItemStatsByItem(historicalItemStats:any[]) {
    const byItem = new Map()
    const psychStatsProps:string[] = [ // would prefer to get this from the data, but this is fine for now
        'item_id'
      , 'item_version' 
      , 'scale_code'
      , 'calib_year'
      , 'psych_run_id'
      , 'created_on'
      , 'change_scale'
      , 'a' , 'b', 'g'
      , 'b1', 'b2', 'b3', 'b4', 'b5', 'b6'
      , 'diff', 'item_mean', 'pbis', 'cpbis', 'exposure'
    ];
    for (let record of historicalItemStats){
      const {item_id, item_version} = record;
      const key = [item_id, item_version].join(';')
      if (!byItem.has(key)){
        byItem.set(key, [])
      }
      byItem.get(key).push(record)
    }
    return {byItem, psychStatsProps}
  }

  processHistoricalItemRegisterByItem(historicalItemRegister:IItemRegister[]) : IItemRegisterByItemTd {
    const byItem: IItemRegisterByItemTd = new Map()
    for (let record of historicalItemRegister){
      const {question_id, test_window_id, test_design_id} = record;
      if (!byItem.has(question_id)){
        byItem.set(question_id, new Map())
      }
      const td_slug = test_window_id+';'+test_design_id;
      byItem.get(question_id).set(td_slug, record)
    }
    return byItem
  }
  processHistoricalItemRegisterSummary(historicalItemRegisterSummary:IItemRegisterSummary[], historicalItemRegister:IItemRegister[]) : IHistoricalItemRegisterModel {
    let latest_tw_id:number;
    const test_windows:IHistoricalItemRegisterModelTw[] = [];
    const twRef:Map<number, IHistoricalItemRegisterModelTw> = new Map();
    const parseTwTitle = (titleJson:string) => {
      try {
        return JSON.parse(titleJson).en;
      }
      catch (e){
        return titleJson
      }
    }
    for (let record of historicalItemRegisterSummary){
      const tw_title_str = parseTwTitle(record.tw_title)
      if (!twRef.has(record.test_window_id)){
        latest_tw_id = Math.max(latest_tw_id || 0, record.test_window_id);
        const tw = {
          is_selected: false,
          id: record.test_window_id,
          title: tw_title_str,
          test_designs: [],
        }
        twRef.set(record.test_window_id, tw);
        test_windows.push(tw)
      }
      const tw = twRef.get(record.test_window_id);
      tw.test_designs.push({
        slug: record.test_window_id+';'+record.test_design_id,
        caption: `[TD: ${record.test_design_id}] (${record.twtar_slug}) ${tw_title_str}`,
        td_id: record.test_design_id
      })
    }
    let historicalProps:{slug:string, caption:string}[] = [];
    if (historicalItemRegister.length > 0){
      const sampleRecord = historicalItemRegister[0];
      Object.keys(sampleRecord).forEach(prop => {
        if (!['test_window_id', 'twtar_slug', 'test_design_id'].includes(prop)){
          const isAnyNonNull = historicalItemRegister.some( record => !!record[prop]);
          if (isAnyNonNull){
            historicalProps.push({slug: prop, caption: prop}) // caption to be updated
          }
        }
      })
    }

    const selected_td_slugs:Map<string, boolean> = new Map()
    if (latest_tw_id){
      const tw = twRef.get(latest_tw_id);
      tw.is_selected = true;
      for (let td of tw.test_designs){
        selected_td_slugs.set(td.slug, true);
      }
    }

    return {
      selected_td_slugs,
      historicalProps,
      test_windows,
      list: historicalItemRegisterSummary,
    }
  }

  public getGroupID() {
    return this.itemBankCtrl.groupId
  }

  /**
   * This function is called to retrieve archived questions
   * @returns - array of the archived questions
   */
  async  getArchivedQuestions() {
    let archivedQuestions = [];
    const question_set_id = this.itemBankCtrl.customTaskSetId;
    //we follow the method from load items so archived questions can still be viewed
    try {
      const res = await this.auth.apiGet(this.routes.TEST_AUTH_ITEM_SET, question_set_id, { query: { getArchived: 'true'} });
  
      for (let questionRecord of res.questions) {
        const q = Object({
          ...JSON.parse(questionRecord.config),
          label: questionRecord.question_label,
          id: questionRecord.id,
          type: questionRecord.item_type
        });
  
        if (q.type === ItemType.SEQUENCE) {
          q.children = [];
        }
        archivedQuestions.push(q);
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error('Error fetching archived questions:', error);
    }
  
    return archivedQuestions.filter( (q) => {
      return !this.itemBankCtrl.getParentId(q) && 
        q.type !== ItemType.SEQUENCE //Assumes children have already been filtered
      ;
    });
  }
  updateCurrentQuestionExpectedAnswer = _.throttle(() => this._updateCurrentQuestionExpectedAnswer(), 1000);
  loadCustomTaskSet(queryParams:{snapshotFilter:string}) {
      const question_set_id = this.itemBankCtrl.customTaskSetId;
      this.auth
        .apiGet(this.routes.TEST_AUTH_ITEM_SET, question_set_id, {query: queryParams})
        .then( (res:IItemSetResponse) => {
          this.myItems.loadMyGroupMembers(res.single_group_id, res.group_id)
              .then(result => {
                this.memberAssignmentCtrl.setGroupMembers(result);
              });

          console.log('TEST_AUTH_ITEM_SET', res)
          
          this.itemBankCtrl.publicPwdProtected = res.public_pwd_protected;
          this.itemBankCtrl.availableTags = res.availableTags || [];
          this.itemBankCtrl.scoringInfo = res.scoringInfo || [];
          const {byItem, psychStatsProps} = this.processHistoricalItemStatsByItem(res.historicalItemStats || []);
          this.itemBankCtrl.historicalItemStats = byItem
          this.itemBankCtrl.psychStatsProps = psychStatsProps
          this.itemBankCtrl.historicalItemRegister = this.processHistoricalItemRegisterByItem(res.historicalItemRegister || []);
          this.itemBankCtrl.itemRegisterSummary = this.processHistoricalItemRegisterSummary(res.historicalItemRegisterSummary || [], res.historicalItemRegister || []);
          this.itemBankCtrl.pendingGraphicReqCountByQuestion = res.pendingGraphicReqCountByQuestion || {}
          this.itemBankCtrl.availableScoringCodes = res.availableScoringCodes || [];
          for(const tag of this.itemBankCtrl.availableTags) {
            this.itemBankCtrl.availableTagsMap[tag.id] = tag;
          }
          this.itemBankCtrl.groupId = res.group_id
          this.itemBankCtrl.single_groupId = res.single_group_id
          console.log(this.routes.TEST_AUTH_ITEM_SET, res)
          this.itemBankCtrl.questions = [];
          this.itemBankCtrl.itemInfo = {};

          for(let questionRecord of res.questions) {
            const q = Object({
              ... JSON.parse(questionRecord.config),
              label: questionRecord.question_label,
              id: questionRecord.id,
              item_version_code: questionRecord.item_version_code,
              type: questionRecord.item_type,
              question_set_id: questionRecord.question_set_id
            });

            if(q.type === ItemType.SEQUENCE) {
              q.children = [];
            }
            this.itemBankCtrl.questions.push(q);
            
            this.itemBankCtrl.itemInfo[questionRecord.id] = {
              order: questionRecord.order,
              parentId: questionRecord.parent_id,
              linkedTags: []
            } //Want this to be separate from the config since they are meta-properties that shouldn't be imported with a config.
          }

          for(const tagLink of res.tagLinks) {
            this.itemBankCtrl.itemInfo[tagLink.item_id].linkedTags.push(this.itemBankCtrl.availableTagsMap[tagLink.tag_id]);
          }
          this.itemBankCtrl.refreshQuestionsView();

          // set active langages
          this.itemBankCtrl.setActiveItemSetLanguages(res.languages);
          this.itemBankCtrl.setSelectedStyleProfile(res.style_profile);
          
          // parse default progress bar configs
          res.defaultProgressBar.forEach(pBarConfig => {
            try {
              const config = JSON.parse(pBarConfig.config);
              this.frameworkCtrl.progressBarDefaults.push({slug: pBarConfig.slug, config });
            } catch(e){
              console.error(e);
            }
          })

          // parse the active framework
          this.frameworkCtrl.asmtFmrk = res.framework ? JSON.parse(<string>res.framework) : FRAMEWORK_CONFIG;
          if (this.itemBankCtrl.questions.length === 0) {
            this.itemBankCtrl.createNewQuestion(ItemType.ITEM);
          }
          this.currentHash = res.hash;
          if (this.frameworkCtrl.isFrameworkView) {
            this.itemBanksUsed = res.childItemBanksInfo;
          }
          this.itemBankCtrl.currentSetName.setValue(res.name);
          this.itemBankCtrl.currentSetDescription = res['description'];
          this.isLoading = false;
          if (this.itemBankCtrl.targetQuestionLabel) {
            this.itemBankCtrl.selectQuestionByLabel(this.itemBankCtrl.targetQuestionLabel);
          } 
          else if (this.itemBankCtrl.targetTargetItemId) {
            this.itemBankCtrl.selectQuestionById(+this.itemBankCtrl.targetTargetItemId);
          } 
          else {
            const firstQ = this.itemBankCtrl.findFirstQuestion();
            if(firstQ) {
              this.itemBankCtrl.selectQuestion(firstQ);            
            } 
          }
          if(this.frameworkCtrl.isFrameworkView) {
            this.itemBankCtrl.switchToPsychometricView();
          }

          // //Disable controls for read-only
          // this.auth.apiGet(this.routes.TEST_AUTH_GROUP_ROLES, res.group_id, { query: {group_id: res.group_id}}).then((roles)=>{
          //   for(const r of roles) {
          //     if(r.role_type === 'test_item_author_rev' ) {
          //       this.authScopeSettings.setSetting(AuthScopeSetting.DISABLE_EDITING, true);
          //     } 
          //     else {
          //       //For clarity. The default should be false anyway.
          //       this.authScopeSettings.setSetting(AuthScopeSetting.DISABLE_EDITING, false);
          //       break;
          //     }
          //   }
          // });
          this.auth.apiGet(this.routes.TEST_AUTH_GROUP_ROLES, res.group_id, {query: {single_group_id: res.single_group_id, group_id: [res.group_id, res.single_group_id]}}).then((roles)=>{
            this.authRoles.currentRoles = roles
            this.myItems.updateRoleFlags(roles.map(r => r.role_type));
            // this.itemEditCtrl.registerFormControlsForDisable();
          });

          let value = res.showComments;
          if (typeof value === 'undefined') {
            value = true;
          }
          this.itemBankCtrl.showComments.setValue(!!(+value));
          this.itemBankCtrl.showComments.valueChanges.subscribe(value => {
            this.auth.apiPatch(this.routes.TEST_AUTH_SHOW_COMMENTS, this.auth.getUid(), {value: value ? 1 : 0})
          });

          if(this.itemFilterCtrl.filterQuestionIds) {
            this.itemFilterCtrl.updateItemFilter();
          }
        }).then(() => {
          if (!this.itemBankCtrl.questions) return;
          this.setLoaderEvent.next(true)
          // const questionIds = this.itemBankCtrl.questions.map(q => q.id);
          // this.auth.apiFind(this.routes.TEST_AUTH_ITEM_IMPRESSION, { query: { question_id: String(questionIds) }})
          //   .then((res) => {
          //     if (res && res.data) {
          //       this.itemBankCtrl.questionImpressions = res.data.reduce((acc, cv: IQuestionImpressionConfig) => {
          //         return { ...acc, [cv.question_id]: cv }
          //       }, {})
          //     }
          //   })
        }).catch((error)=>{
          console.log(error)
        });
  }
  initCustomTaskSet(queryParams:{snapshotFilter:string}) {
    this.userSub = this.auth.user().subscribe(userInfo => {
      if (userInfo && !this.isUserInited) {
        this.isUserInited = true;
        this.loadCustomTaskSet(queryParams);
      }
    })
  }
  saveChanges(editParam?: boolean) {
    this.isSaveChangedRequired = true;
    try {
      this.saveCurrentQuestionData(undefined, undefined, editParam);
    } catch (e) { }
    this.saveMainBlob((res) => {
      this.isSaveChangedRequired = false;
      this.fakeNeedForSaveAgain();
    });
  }
  saveMainBlob(then: (res: any) => void) {
    this.saveConflictWarningGiven = false;
    this.saveContent();
  }
  saveItemSet(override?: boolean, info?:{name?:string, description?:string, languages?:string, style_profile?:EStyleProfile}) {
    if (!info){ info = {} }
    this.isSavingItemSet = true;
    this.frameworkCtrl.asmtFmrk.testFormType = this.frameworkCtrl.testFormConstructionMethod.value;
    this.frameworkCtrl.asmtFmrk.questionWordSlug = this.frameworkCtrl.questionWordingFc.value;
    return this.auth.apiPatch(
      this.routes.TEST_AUTH_ITEM_SET,
      this.itemBankCtrl.customTaskSetId,
      {
        ... info,
        oldHash: this.currentHash,
        override
      }
    )
    .then(res => {
      if (res.hash) { this.currentHash = res.hash; }
      if (info.name){ this.itemBankCtrl.currentSetName.setValue(info.name) }
      if (info.description !== null){ this.itemBankCtrl.currentSetDescription = info.description; }
      if (info.languages){this.itemBankCtrl.setActiveItemSetLanguages(info.languages);}
      if (info.style_profile){this.itemBankCtrl.setSelectedStyleProfile(info.style_profile);}
      this.isSavingItemSet = false;
    })
    .catch(e => {
      if (e.message === 'REQ_OVERRIDE') {
        const proceed = confirm('Someone has saved this item set info since you last saved or opened it. Would you like to overwrite their changes?');
        if (proceed) {
          return this.saveItemSet(true, info);
        }
      } 
      else {
        alert('cannot save');
      }
      this.isSavingItemSet = false;
    });
  }
  saveContent(override?: boolean) {
    let test_design_question_sets;
    if (this.isSavingDisabled()){
      return Promise.resolve();
    }
    if (this.frameworkCtrl.isFrameworkView) {
      test_design_question_sets = this.itemBanksUsed.map(itemBank => itemBank.id);
    }
    this.isSavingItemSet = true;
    this.frameworkCtrl.asmtFmrk.testFormType = this.frameworkCtrl.testFormConstructionMethod.value;
    this.frameworkCtrl.asmtFmrk.questionWordSlug = this.frameworkCtrl.questionWordingFc.value;
    console.log(this.frameworkCtrl.getLinearFormQuestions())
    return this.auth.apiPatch(
      this.routes.TEST_AUTH_FRAMEWORKS,
      this.itemBankCtrl.customTaskSetId,
      {
        test_design_question_sets,
        framework: JSON.stringify(this.frameworkCtrl.asmtFmrk),
        oldHash: this.currentHash,
        style_profile: this.frameworkCtrl.asmtFmrk.styleProfile,
        override
      }
    )
    .then(res => {
      if (res.hash) {
        this.currentHash = res.hash;
      }
      this.isSaveChangedRequired = false;
      this.isSavingItemSet = false;
    })
    .catch(e => {
      if (e.message === 'REQ_OVERRIDE') {
        const proceed = confirm('Someone has saved this framework since you last saved or opened it. Would you like to overwrite their changes?');
        if (proceed) {
          return this.saveContent(true);
        }
      } else {
        alert('cannot save');
      }
      this.isSavingItemSet = false;
    });
  }
  fakeItemSetSaveBuffer() {
    this.isLoadingItemSets =  true;
    setTimeout(() => {
      this.isLoadingItemSets =  false;
    }, 400);
  }
  fakeNeedForSaveAgain() {
    if (this.existingFakeSaveDelayTimeout) {
      clearTimeout(this.existingFakeSaveDelayTimeout);
    }
    this.existingFakeSaveDelayTimeout = setTimeout(() => {
      this.isSaveChangedRequired = true;
    }, 5000);
  }
  _updateCurrentQuestionExpectedAnswer() {
    updateCurrentQuestionExpectedAnswer(
      this.itemBankCtrl.getCurrentQuestionContent(), // currentQuestion, 
      this.itemBankCtrl.currentQuestion, // currentMetaContainer, 
      this.frameworkCtrl.identifySingleEntryParams()
    )
  }


  saveAssessmentFramework() {
    this.saveAssessmentFrameworkId();
    if (this.frameworkCtrl.isEditingFramework) {
      this.saveAsmtFwrkEdits();
    }
  }
  saveAssessmentFrameworkId = () => this.saveChanges();

  loadDocument = async  (documentMap:Map<number, IQuestionConfig>, itemId:number, isEnglish:boolean=false) : Promise<IQuestionConfig> => {
    if (itemId){
      if (documentMap.has(itemId)){
        return documentMap.get(itemId)
      }
      const questionRecord = await this.auth.apiGet(
        this.routes.TEST_AUTH_QUESTIONS,
        itemId,
        {
          query: {isSkipRecentUse: true}
        }
      )
      const questionContent:IQuestionConfig = JSON.parse(questionRecord.config);
      let questionDisplay = questionContent;
      if (!isEnglish){
        questionDisplay = questionContent.langLink
      }
      documentMap.set(+itemId, questionDisplay);
      return questionDisplay;
    }
    throw new Error()
  }

  saveCurrentQuestionData(forceAllow?: boolean, description?:string, isEditParam?: boolean) {
    return this.saveTargetQuestionData(this.itemBankCtrl.currentQuestion, false, forceAllow, description, isEditParam);
  }

  saveTargetQuestionData(targetQuestion: IQuestionConfig, isSavingMultiple?: boolean, forceAllow?: boolean, descriptionOfChange?:string, isEditParam?: boolean) {
    if (!isSavingMultiple && this.isSaving) {
      return Promise.resolve();
    }

    this.refreshScoreMatrices(targetQuestion);

    if (!forceAllow && this.isSavingDisabled() && !isEditParam){
      if(this.itemBankCtrl.isSuggestionMode(this.itemBankCtrl.getQuestionContent(targetQuestion)) && !forceAllow) {
        return this.saveSuggestions(targetQuestion); //intercept and save suggestions instead
      } else {
        return Promise.resolve();
      }
    }
    return new Promise((resolve, reject) => {
      if (!targetQuestion) {
        return resolve();
      }
      const previousQuestion = targetQuestion;
      let previousQuestionState;
      if (previousQuestion && previousQuestion.id === this.itemBankCtrl.questionStateOnLoad.id) {
        previousQuestionState = this.itemBankCtrl.questionStateOnLoad.state;
      }
      return this.saveQuestionData(previousQuestion, previousQuestionState, false, forceAllow, descriptionOfChange, isEditParam)
      .then(res => resolve(res))
        .catch(e => reject(e));
    });
  }

  restoreCurrentQuestionData() {
    this.setInspecting(false); //Do this before saving since we need to enable editing to load the new revision
    
    const restoreEN = this.restoreENFc.value;
    const restoreFR = this.restoreFRFc.value;
    const restoreParams = this.restoreParamsFc.value;
    const restoreLabel = this.restoreLabel.value;

    //If we are not restoring something, we need to load the previous version of that section first
    if(!restoreEN || !restoreFR || !restoreParams) {
      this.restoreRevision({skipEN: restoreEN, skipFR: restoreFR, skipParams: restoreParams, skipLabel: restoreLabel});
    }
  }

  restoreRevision(skipOptions? : {skipEN: boolean, skipFR: boolean, skipParams: boolean, skipLabel: boolean}){
    const revisionList = this.currentQuestionRevisions.displayList;
    if(revisionList && revisionList.length > 0){
      this.loadQuestionRevision(revisionList[0].id, false, skipOptions);
    }
    this.isRevisionsOpen = false;
  }

  loadPreviousRevision() {
    if (this.currentQuestionRevisions && this.itemBankCtrl.currentQuestion){
      const restoration = JSON.parse(this.currentQuestionRevisions.originalQuestion);
      Object.keys(restoration).forEach(prop => {
        this.itemBankCtrl.currentQuestion[prop] = restoration[prop];
      })
    }
  }

  cancelInspect() {
    this.isLoadingVersionId = null;
    this.setInspecting(false);
    this.itemBankCtrl.resetQuestionState();
    if (this.currentQuestionRevisions){
      this.currentQuestionRevisions.displayList = null; //Hide the revision history window after cancelling
      this.loadPreviousRevision();
    }
    this.isRevisionsOpen = false;
  }

  setInspecting(inspecting: boolean) {
    this.isInspecting = inspecting;
    this.editingDisabled.setEditingDisabled(inspecting);
  }

  saveQuestionData(question: IQuestionConfig, previousQuestionState: string, isEditing: boolean= false, forceAllow: boolean = false, change_note?:string, isEditParam?:boolean ) {
    
    if(this.itemBankCtrl.isSuggestionMode(this.itemBankCtrl.getQuestionContent(question)) && !forceAllow) {
      return this.saveSuggestions(question); //intercept and save suggestions instead
    }
    
    return new Promise((resolve, reject) => {
      if (!question) {
        return resolve();
      }
      if (!forceAllow && this.isSavingDisabled() && !isEditParam){
        return resolve(null);
      }
      
      // add function to extract asset ids
      let itemAssetsVersionIds = this.extractAssetVersionIds(question);
      this.itemBankCtrl.sanitizeQuestionBeforeSave(question);
      const config = JSON.stringify(question);
      const id = question.id;
      if (config === previousQuestionState) {
        return resolve();
      }
      const diff = DeepDiff(JSON.parse(previousQuestionState || '{}'), question, ignoreFromDiff);
      
      // Save the changes made
      this.itemDiffCtrl.refreshDiffs(question);
      const affected_langs = new Set();
      diff.forEach(diff =>{
        if(diff.path[0] === 'content' ){
          affected_langs.add('en');
        }
        if(diff.path[0] === 'langlink' && diff.path[1] === 'content'){
          affected_langs.add('fr');
        }
      })
      const question_label = question.label;
      const is_editing = isEditing ? 1 : 0;
      this.isSaving = true;

      const parent_id = this.itemBankCtrl.getParentId(question);

      return this.auth.apiPatch(
        this.routes.TEST_AUTH_QUESTIONS,
        id,
        {
          question_label,
          config,
          order: this.itemBankCtrl.getOrder(question),
          diff: JSON.stringify(diff),
          is_editing,
          change_note,
          last_touched_by: this.auth.getDisplayName(),
          item_asset_version_ids: itemAssetsVersionIds,
          parent_id,
          item_asset_ids: this.getAssetIDs(question),
          affected_langs: [...affected_langs]
        }
      )
      .then(res => {
        this.isSaving = false;
        this.itemBankCtrl.questionStateOnLoad.id = id;
        this.itemBankCtrl.questionStateOnLoad.state = config;
        if (this.itemBankCtrl.currentQuestion && this.itemBankCtrl.currentQuestion.id === this.itemBankCtrl.questionStateOnLoad.id){
          console.log('viewing same question')
          this.itemBankCtrl.currentQuestion.updated_on = (new Date()).toISOString();
        }
        resolve(res);
      })
      .catch(e => {
        this.isSaving = false;
        reject(e);
      });
    });
  }


  private getAssetIDs(question: IQuestionConfig): number[] {
    let assetIdSet = new Set<number>();
    const getAssetIds = (elements: IContentElement[], assetIdSet: Set<number>) => {
      if (elements) {
        for(const element of elements) {
          if(element.assetId) {
            assetIdSet.add(element.assetId);
          }
          getAssetIds(getElementChildren(element), assetIdSet);
        }
      }
    }
    getAssetIds(question.content, assetIdSet);
    return Array.from(assetIdSet);
  }
  
  autoSaveQuestionData() {
    // !this.itemBankCtrl.isEditActivationPending() &&
    if (this.itemBankCtrl.currentQuestion && !this.isInspecting) {
      const question = this.itemBankCtrl.currentQuestion;
      let previousQuestionState;
      if (this.itemBankCtrl.questionStateOnLoad && this.itemBankCtrl.currentQuestion.id === this.itemBankCtrl.questionStateOnLoad.id) {
        previousQuestionState = this.itemBankCtrl.questionStateOnLoad.state;
      }
      this.saveQuestionData(question, previousQuestionState, true);
      this.autoSaveSub.next();
    }
  }

  isRevisionsOpen:boolean;
  loadCurrentQuestionRevisions() {
    if (this.isRevisionsOpen){
      this.cancelInspect()
      return 
    }
    this.isRevisionsOpen = true;
    this.currentQuestionRevisions = {};
    this.currentQuestionRevisions.originalQuestion = JSON.stringify(this.itemBankCtrl.currentQuestion)
    if (this.currentQuestionRevisions.displayList) {
      this.currentQuestionRevisions.displayList = null;
      if(this.isInspecting) {
        this.cancelInspect();
      }
    } else {
      this.isLoadingRevisions = true;
      this.auth.apiFind(
        this.routes.TEST_AUTH_QUESTION_REVISIONS,
        { query: {test_question_id: this.itemBankCtrl.currentQuestion.id} }
      )
      .then(res => {
        const displayList = [];
        let lastDate: string;
        for (let i = 0; i < res.length; i++) {
          const entry = res[i];
          let createdOnDate = moment(entry.created_on).format('MMMM DD, YYYY');
          const createdOnTime = moment(entry.created_on).format('h:mma');
          if (createdOnDate === lastDate) {
            createdOnDate = '';
          } else {
            lastDate = createdOnDate;
          }
          displayList.push({
            ... entry,
            createdOnDate,
            createdOnTime,
          });
        }
        this.currentQuestionRevisions.displayList = displayList;
        this.isLoadingRevisions = false;
      })
      .catch(e => {
        alert('Could not load question revisions');
        this.isLoadingRevisions = false;
      });
    }
  }


  extractAssetVersionIds(question: IQuestionConfig) {
    let accumlator_array = [];
    extractQuestionAssetVersionIds(question.content, accumlator_array);
    if (question.langLink) {
      extractQuestionAssetVersionIds(question.langLink.content, accumlator_array);
    }
    return accumlator_array;
  }

  saveAsmtFwrkEdits() {
    let frameworkPayloadBase: Partial<IAssessmentFrameworkDef> = {
      caption: this.frameworkCtrl.asmtFmrk.caption,
      subcaption: this.frameworkCtrl.asmtFmrk.subcaption,
      timeLastTouch: serverTimestamp(),
    };
    throw new Error('wip');
  }


  loadQuestionRevision(id: number, inspecting: boolean, skipOptions?: {skipEN: boolean, skipFR: boolean, skipParams: boolean, skipLabel: boolean}) {
    console.log('loadQuestionRevision');
    this.setInspecting(inspecting);
    if (id === this.currentQuestionRevisions.restoringToId) {
      return;
    }

    if (id === this.currentQuestionRevisions.restoredToId && 
      this.equivalentSkipOptions(skipOptions, this.currentQuestionRevisions.skippedOptions)) {
      this.cancelInspect();
      return;
    }

    this.currentQuestionRevisions.restoringToId = id;
    const completeLoad = () => {
      this.currentQuestionRevisions.restoringToId = null;
      
      //!inspecting indicates we are leaving inspect mode (restoring) when loading
      if(!inspecting) {
        //Only save once the load has been completed
        this.saveCurrentQuestionData();
        this.currentQuestionRevisions.displayList = null;
        this.itemEditCtrl.closeElementRestore(); //close the restore options dialog
      }
    };
    this.isLoadingQuestionVersion = true;
    this.isLoadingVersionId = id;
    this.auth
      .apiGet( this.routes.TEST_AUTH_QUESTION_REVISIONS, id )
      .then(res => {
        this.itemBankCtrl.resetQuestionState();
        console.log('load revision', id, res, this.currentQuestionRevisions);
        const questionRestored = JSON.parse(res.config);
        const targetQuestion = this.itemBankCtrl.currentQuestion;
        questionRestored.id = targetQuestion.id;
        Object.keys(questionRestored).forEach(key => {
          if(skipOptions) {
            //If we are restoring these keys, that means we shouldn't load the previous value of them.
            if(skipOptions.skipParams && key === 'meta') {
              return;
            }
            if(skipOptions.skipLabel && key === 'label') {
              return;
            }
            if(skipOptions.skipFR && key === 'langLink') {
              return;
            }
            if(skipOptions.skipEN && key !== 'langLink' && key !== 'meta') {
              return;
            }
          }
          targetQuestion[key] = questionRestored[key];
          // During inspect preview or if restoring with the label, update the label on right panel also
          if (!skipOptions || !skipOptions.skipLabel) this.itemBankCtrl.currentQuestionLabel.setValue(questionRestored.label);
        });
        this.currentQuestionRevisions.restoredToId = id;

        this.currentQuestionRevisions.skippedOptions = skipOptions;
        
        completeLoad();
      })
      .catch(e => {
        alert('Could not inspect question revision');
        completeLoad();
      }).finally(()=> {
        this.isLoadingQuestionVersion = false;
      });
  }

  equivalentSkipOptions(a?: {skipEN: boolean, skipFR: boolean, skipParams: boolean, skipLabel: boolean}, b?: {skipEN: boolean, skipFR: boolean, skipParams: boolean, skipLabel: boolean}): boolean {
    //not having a skip option implies all the skip options were false.
    const skipKeys = ['skipEN', 'skipFR', 'skipParams', 'skipLabel'];
    return (!a && !b) || 
    (a && b && _.every(skipKeys, key => a[key] == b[key])) ||
    (!a && b && _.every(skipKeys, key => !b[key])) ||
    (a && !b && _.every(skipKeys, key => !a[key]));
  }

  isSavingDisabled = () => {
    return this.editingDisabled.isReadOnly(this.itemBankCtrl.isQLockAvail());
  };

  saveRevisionMessage(revision, message: string) {
    this.auth.apiPatch(this.routes.TEST_AUTH_QUESTION_REVISIONS, revision.id, {message}).then(() => {
      revision.message = message;
    })
  }

  createSuggestionState() {
    const suggestionState = this.itemBankCtrl.getCurrentQuestionContent();

    this.isSuggesting = true;
    this.auth.apiCreate(this.routes.TEST_AUTH_SUGGESTIONS,{
      origin_question_version_id: this.itemBankCtrl.currentQuestion.versionId,
      config: JSON.stringify(suggestionState),
      lang: this.lang.c()
    }).then( () => {
      this.isSuggesting = false;
      if(!this.itemComponentEdit.usingEditingMode()) {
        this.itemDiffCtrl.refreshSuggestions();
      }
    }).catch(() => {
      this.isSuggesting = false;
    });
  }

  saveSuggestions(question?) {
    if(!question && !this.itemBankCtrl.currentQuestion) {
      return Promise.resolve();
    }

    if(!question) {
      question = this.itemBankCtrl.currentQuestion;
    }
    const prevState = this.itemComponentEdit.suggestionStateOnLoad;
    const suggestionState = this.itemComponentEdit.suggestion.state;

    this.itemBankCtrl.sanitizeQuestionBeforeSave(suggestionState, true);

    const diffs = this.itemComponentEdit.deepDiff(prevState, suggestionState);
    
    if(!diffs) {
      return Promise.resolve();
    }

    const changes = []; //For tracking author and time of individual changes
    for(const diff of diffs) {
      const element = this.itemComponentEdit.getElementFromDiff(diff);
      const entryId = element?.entryId;
      if(entryId) {
        if(diff.kind === 'E') {
          changes.push({entryId, prop: diff.path[diff.path.length - 1]})
        } else {
          changes.push({entryId});
        }
      }
    }

    this.isSuggesting = true;
    this.isSaving = true;
    return this.auth.apiPatch(this.routes.TEST_AUTH_SUGGESTIONS, this.itemComponentEdit.suggestion?.id, {
      origin_question_version_id: question.versionId,
      config: JSON.stringify(suggestionState),
      lang: this.lang.c(),
      changes
    }, {
      query: {question_id: question.id} 
    }).then( () => {
      this.isSuggesting = false;
      this.isSaving = false;
    }).catch(() => {
      this.isSuggesting = false;
      this.isSaving = false;
    });
  }

  /** Update the scoreMatrix object of any respondable blocks in the question (used before saving question) */
  refreshScoreMatrices(targetQuestion: IQuestionConfig){
    const question = this.itemBankCtrl.getQuestionContent(targetQuestion)
    const entryOrder = question?.entryOrder
    entryOrder?.forEach(entryId => {
      const targetElem = deepFind(question, 'entryId', entryId)
      if (targetElem) {
        refreshScoreMatrix(targetElem)
      }
    })
  }

}