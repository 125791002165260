import { IProgressBarConfig } from "src/app/ui-item-maker/item-set-editor/models/assessment-framework"
import { DEFAULT_PROGRESS_BUTTON_BORDER_WIDTH } from "src/app/ui-testrunner/tr-progress-bar/tr-progress-bar.component"

// Has to be function so object isn't shared
export const CUSTOM_PROGRESS_BAR_CONFIG = (): IProgressBarConfig => {
    return {
      completed: {
        background: {
          color: "#fac73a",
  
        },
        border: {
          color: "#fac73a",
          width: DEFAULT_PROGRESS_BUTTON_BORDER_WIDTH,
  
        },
        font: {
          color: '#000000',
          weight: 400
        }
      },
      selected: {
        background: {
          color: "#12365a",
        },
        border: {
          color: "#12365a",
        },
        font: {
          color: '#ffffff',
          weight: 400
        }
      },
      initial: {
        background: {
          color: "#ffffff",
  
        },
        border: {
          color: '#000000',
          width: DEFAULT_PROGRESS_BUTTON_BORDER_WIDTH,
        },
        font: {
          color: '#000000',
          weight: 400
        }
      },
      hover: {
        background: {
          color: "#dfdfdf",
        },
        border: {
          color: '#000000',
          width: DEFAULT_PROGRESS_BUTTON_BORDER_WIDTH,
        },
        font: {
          color: '#000000',
          weight: 400
        }
      },
      completedSelected: {
        background: {
          color: "#fac73a",
        },
        border: {
          color: "#12365a",
        },
        font: {
          color: "#000000",
          weight: 400
        }
      }
    }
  }
  
  export const PROGRESS_BAR_STATE_ARRAY:PROGRESS_BAR_STATE[] = [
    'initial',
    'completed',
    'selected',
    'hover',
    'completedSelected'
  ]
  
  export type PROGRESS_BAR_STATE =
    'initial'
    |'selected'
    |'completed'
    |'hover'
    |'completedSelected'
  
  export const FONT_WEIGHT_OPTIONS = [
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900'
  ]