import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IQuestionConfig } from '../models';
import { IProgressBarConfig, IProgressBarStageConfig } from 'src/app/ui-item-maker/item-set-editor/models/assessment-framework';

export const DEFAULT_PROGRESS_BUTTON_BORDER_WIDTH = 0.05;

@Component({
  selector: 'tr-progress-bar',
  templateUrl: './tr-progress-bar.component.html',
  styleUrls: ['./tr-progress-bar.component.scss']
})
export class TrProgressBarComponent implements OnInit {

  constructor() { }

  @Input() currProgressLoc;
  @Input() currProgressLocAsNum;
  @Input() currProgressBySession;
  @Input() isHidden;
  @Input() useProgressBySession;
  @Input() testRunnerSections;
  @Input() useLessPadding; 
  @Input() isPj;
  @Input() isG6;
  @Input() isReadingSelection : Function;
  @Input() isQuestionFilled: Function;
  @Input() getQuestionTitle: Function; //not used anymore, might be used in future. Keeping for now.
  @Input() currentQuestions;
  @Input() currentQuestionIndex;
  @Input() isQuestionnaire?;
  @Input() isCustom: boolean;
  @Input() customConfig: IProgressBarConfig;
  @Input() isPreview: boolean = false;
  
  @Output() selectQuestion = new EventEmitter();
  
  pjQIndex = 0;

  ngOnInit(): void {
    console.log(navigator.platform)
  }

  resetPjQIndex(){
    this.pjQIndex = 0;
  }

  incPjQIndex() {
    return ++this.pjQIndex;
  }

  getProgressBarProgress(){
    if(this.currProgressLocAsNum * 2 > 100)
      return 100
    return this.currProgressLocAsNum * 2
  }

  getProgressBarProgress_secondRow(){
    if(this.currProgressLocAsNum * 2 < 100)
      return 0
    return this.currProgressLocAsNum * 2 - 100
  }

  getProgressBarProgressiPad(){
    if(this.currentQuestions.length <= 6){  // one row
      return this.currProgressLocAsNum;
    }
    if(this.currentQuestions.length <= 12){  // two rows
      return this.processProgressNum(this.currProgressLocAsNum * 2 + 1);
    }
    if(this.currProgressLocAsNum * 3 >= 100){  
        return 100
    } 
    return this.processProgressNum(this.currProgressLocAsNum * 3 + 4)
    
  }

  getProgressBarProgressiPad_second(){

    if(this.currentQuestions.length > 12){   // if three rows
      if(this.currProgressLocAsNum * 3 >= 200)
        return 100
      if(this.currProgressLocAsNum * 3 < 200 && this.currProgressLocAsNum * 3 > 100)
        return this.processProgressNum(this.currProgressLocAsNum * 3 - 100);
      if(this.currProgressLocAsNum * 3 < 100)
        return 0;
    } 
    else if(this.currentQuestions.length > 6){   // if two rows
      if(this.currProgressLocAsNum * 2 < 100)
        return 0
      return this.processProgressNum(this.currProgressLocAsNum * 2 - 100);
    }
  }

  getProgressBarProgressiPad_third(){
    if(this.currProgressLocAsNum * 3 < 200)
      return 0
    else if(this.currProgressLocAsNum == 100){
      return 100
    }
    return this.processProgressNum(this.currProgressLocAsNum * 3 - 210);
  }

  processProgressNum(progressAsNumber: number){
    let numArray = [0, 20, 40, 60, 80, 100]
    let closestIndex = -1;
    let closestDist = 1000000;
    for (const element of numArray){
      if (Math.abs(element - progressAsNumber) < closestDist){
        closestDist = Math.abs(element - progressAsNumber);
        closestIndex = numArray.indexOf(element)
      }
    }

    return numArray[closestIndex];
  }


  isDeviceiPad(){
    // console.log('is iPad? ', (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad')
    return (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad';
  }

  isIOS() {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      return true;
    } else {
      return navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform);
    }
  }

  //Progress bar controls
  /**
   * Hide left progress bar for the first question and there are more than one questions
   * @param qIndex 
   * @returns 
   */
  isLeftProgressBarHidden(qIndex: number){
    return qIndex == 0                     
    && this.currentQuestions.length != 1   && !this.isPreview
  }

  /**
   * Hide right progress bar for the last question and there are more than one questions
   * @param qIndex 
   * @returns 
   */
  isRightProgressBarHidden(qIndex: number){
    return qIndex == this.currentQuestions.length - 1 
    && this.currentQuestions.length != 1 && !this.isPreview
  }
  setActiveHoverIndex(qIndex: number | null){
    this.activeHoverIndex = qIndex
  }

  activeHoverIndex:number;
  getCustomStyle(qIndex:number, question:any){
    const  {completed, completedSelected, initial, selected, hover}= this.customConfig;
    const isFilled = this.isQuestionFilled(qIndex) && !this.isReadingSelection(question);
    const isActive = this.currentQuestionIndex === qIndex;
    const isHover = qIndex === this.activeHoverIndex;
    let state:IProgressBarStageConfig;
    if(isActive){
      if(isFilled){
        state = completedSelected
      } else {
        state = selected;
      }
    } else if (isHover){
      state = hover;
    } else if (isFilled){
      state = completed;
    } else {
      state = initial;
    }
    if(this.isPreview){ state = this.customConfig[this.currentQuestions[qIndex]]} // so editor can see config

    return {
      'background-color': state.background.color,
      'color': state.font.color,
      'font-weight': state.font.weight,
      'border-color': state.border.color,
      'border-width': (isActive?  DEFAULT_PROGRESS_BUTTON_BORDER_WIDTH * 3 : state.border.width) + 'em',
      'border-style': 'solid' 
    }
  }

  isBarActive(inactive, active){
    if(inactive || (this.isPreview && this.currentQuestions[0] !== 'selected')){
      return '#DFDFDF'
    }
    if (active || this.isPreview){
      return this.customConfig.selected.background.color
    }
  }
}
