import * as _ from 'lodash';
import { AuthScopeSetting, AuthScopeSettingsService} from "../../auth-scope-settings.service";
import { AuthService} from '../../../api/auth.service';
import { EditingDisabledService} from '../../editing-disabled.service';
import { EditViewMode } from '../models/types';
import { identifyQuestionResponseEntries, ensureResponseEntryIds, ExpectedAnswerSummary } from '../models/expected-answer';
import { EStyleProfile, StyleprofileService} from '../../../core/styleprofile.service';
import { FormControl } from '@angular/forms';
import { IAssessmentPartition } from '../models/assessment-framework';
import { IItemTag } from '../../item-tag/item-tag.component';
import { Injectable } from "@angular/core";
import { IQuestionConfig, ISequenceConfig, IQuestionImpressionConfig, ElementType, IQuestionScoringInfo, IScoringCodes, IItemRegisterSummary, IItemRegister, IHistoricalItemRegisterModel, IItemRegisterByItemTd} from '../../../ui-testrunner/models';
import { ItemBankSaveLoadCtrl } from './save-load';
import { ItemBankUtilCtrl } from './util';
import { ItemComponentEditService } from '../../item-component-edit.service';
import { ItemDiffCtrl } from './item-diff';
import { ItemEditCtrl } from './item-edit';
import { ItemFilterCtrl } from './item-filter';
import { ItemSetFrameworkCtrl } from './framework';
import { ItemSetPreviewCtrl } from './preview';
import { CustomButtonPos, DEF_CUSTOM_BTN_BG_COLOR, DEF_CUSTOM_BTN_FG_COLOR, ItemType, ItemTypeDefs, itemTypes } from '../../models';
import { LangService } from '../../../core/lang.service';
import { LoginGuardService } from "../../../api/login-guard.service";
import { map, startWith } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { PARAM_SPECIAL_FLAGS } from '../../framework-dimension-editor/model';
// import { PARAM_SPECIAL_FLAGS} from '../../framework-dimension-editor/framework-dimension-editor.component';
import { possibleLanguages } from '../models/constants';
import { RoutesService } from '../../../api/routes.service';
import { createDefaultElement } from '../models';
import { IContentElementText } from 'src/app/ui-testrunner/element-render-text/model';
import { target } from 'webpack.prerender.config';
import { Destroyable } from './destroyable';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';
import {IPendingGraphicReqCountMap} from './../../graphics-workflow-section/types'
import { deepFind } from '../../services/util';
import { generatePossibleElementCombinations } from '../../config-score-matrix/expected-answer-validation/generate-combinations';
import { IS_TRACK_CHANGES_DISABLED } from '../../widget-authoring-main/widget-authoring-main.component';

const MAX_ITEM_CLONES = 1000;

@Injectable({
  providedIn: 'root'
})
export class ItemBankCtrl implements Destroyable {
  
  questionStateOnLoad: {id: number, state: string};
  searchItemList = [];
  isSearchingItems = false;
  itemList = [];
  itemListLength = 12;
  currentItemListPage = 1;
  totalItemListPages = 0;
  questions: IQuestionConfig[];
  questionImpressions: IQuestionImpressionConfig[];
  topLevelQuestions: IQuestionConfig[];
  sequences: ISequenceConfig[];
  dropListIds: string[];
  itemInfo: {[key:number] : { order: number, parentId: number, linkedTags: IItemTag[]}}
  currentQuestion: IQuestionConfig;
  styleProfileSelector = new FormControl();
  itemBankEditForm:{
    name: FormControl,
    description: FormControl,
    languages: {id:string, caption: string, fc: FormControl}[],
    styleProfile: FormControl;
  };
  selectQuestionSub = new Subject<IQuestionConfig>();
  createNewQuestionSub = new Subject<any>();
  currentQuestionLabel = new FormControl();
  currentQuestionTestLabel = new FormControl();
  currentQuestionReadSel = new FormControl()
  currentSetName = new FormControl();
  currentSetDescription:string;
  currentQuestionNotes = new FormControl();
  customTaskSetId: string;
  activeItemSetLanguages = new Map();
  numItemSetLanguages = 0;
  selectedSection: IAssessmentPartition;
  recentlyAddedQuestion;
  // labelCounter = 0;
  labelCounter = {}
  activeQuestionState = {};
  suggestionQuestionState = {};
  targetQuestionLabel: string;
  targetTargetItemId: string | number;
  private qNoteSub:Subscription;
  private qLabelSub:Subscription;
  private qTestLabelSub:Subscription;
  private qRealSelSub:Subscription;
  private styleProfileSub:Subscription;
  personalEditorSettings:any;
  isPsychometricInited: boolean;
  isPsychometricViewEnabled: boolean;
  editModeItemId: number;
  showComments: FormControl = new FormControl();
  publicPwdProtected: boolean;
  isFocusView:boolean = false;
  currentQuestionNavigation:any = null;
  currentQuestionNavigationIsAnchor:boolean = false;
  currentQuestionNavigationLabel = new FormControl();
  currentQuestionLockByNewItemLabel = new FormControl('');
  currentQuestionLockBy:string = '';
  currentQuestionLockBySectionId: number = null;
  currentQuestionLockByItem:any = null;
  currentQuestionLockByItems:any = [];
  currentQuestionLockByChoiceId: number = null;
  currentQuestionLockByEntryId: number = null;

  tagFc = new FormControl();
  availableTags: IItemTag[] = [];
  availableTagsMap: {[key:number]: IItemTag} = {};
  filteredTags: Observable<IItemTag[]>;

  groupId: number;
  single_groupId: number;

  scoringInfo: IQuestionScoringInfo[];
  pendingGraphicReqCountByQuestion:IPendingGraphicReqCountMap = {};
  

  public itemRegisterSummary:IHistoricalItemRegisterModel;
  public historicalItemRegister: IItemRegisterByItemTd;
  public historicalItemStats: Map<string, any>;
  public psychStatsProps: string[];
  public availableScoringCodes: IScoringCodes[];

  public frameworkCtrl: ItemSetFrameworkCtrl;
  public itemFilterCtrl:ItemFilterCtrl;
  public itemEditCtrl:ItemEditCtrl;
  public saveLoadCtrl: ItemBankSaveLoadCtrl;
  public itemDiffCtrl: ItemDiffCtrl;
  public previewCtrl: ItemSetPreviewCtrl;


  public isEditing: boolean;
  public isEditingEnabled: boolean;
  editViewMode: EditViewMode = EditViewMode.AFTER;


  constructor(
    private auth: AuthService,
    public profile: StyleprofileService,
    public routes: RoutesService,
    public lang: LangService,
    public editingDisabled: EditingDisabledService,
    private authScopeSettings: AuthScopeSettingsService,
    private itemComponentEdit: ItemComponentEditService,
    private loginGuard: LoginGuardService,
    private whiteLabel: WhitelabelService,
  ){
    this.questions = [];
    this.questionImpressions = [];
    this.sequences = [];
    this.initSubscriptions();
    this.personalEditorSettings = {
      isPsychometricsEnabled: true,
    };
    
    this.editingDisabled.isInEditor = true;

    window['selectNextQuestion'] = () => this.selectNextQuestion();
    window['getCurrentQuestion'] = () => this.currentQuestion;
    window['identifyQuestionResponseEntries'] = this.identifyQuestionResponseEntries;

    for(const type of itemTypes) {
      this.labelCounter[type.id] = 0; 
    }

    this.filteredTags = this.tagFc.valueChanges.pipe(
      startWith(''),
      map(value => this._filterTag(value))
    )
  }

  private _filterTag(value:string): IItemTag[] {
    const filterValue = value.toLowerCase();
    return this.availableTags.filter(option => !this.getCurrLinkedTags().filter(t=>t.id === option.id).length && option.caption.toLowerCase().includes(filterValue));
  }

  getCurrLinkedTags() {
    return this.itemInfo[this.currentQuestion.id]?.linkedTags || [];  
  }

  destroy(){
    if (this.qNoteSub) { this.qNoteSub.unsubscribe(); }
    if (this.qLabelSub) { this.qLabelSub.unsubscribe(); }
    if (this.qTestLabelSub) { this.qTestLabelSub.unsubscribe(); }
    if (this.qRealSelSub) { this.qRealSelSub.unsubscribe(); }
    if (this.styleProfileSub) {this.styleProfileSub.unsubscribe();}
  }

  public util = new ItemBankUtilCtrl();

  initSubscriptions(){

    this.styleProfileSub = this.styleProfileSelector.valueChanges.subscribe( styleProfile => {
      if(styleProfile) {
        this.profile.setStyleProfile(styleProfile, true);
      }
    });

    this.qNoteSub = this.currentQuestionNotes.valueChanges.subscribe( e => {
      if(this.currentQuestion) {
        this.currentQuestion.notes = this.currentQuestionNotes.value;
      }
    });

    this.qLabelSub = this.currentQuestionLabel.valueChanges.subscribe( e => {
      if(this.currentQuestion) {
        this.currentQuestion.label = this.currentQuestionLabel.value;
        this.currentQuestion.langLink.label = this.currentQuestionLabel.value;
      }
    });

    this.qTestLabelSub = this.currentQuestionLabel.valueChanges.subscribe(e=>{
      if (this.currentQuestion) {
        this.currentQuestion.testLabel = this.currentQuestionTestLabel.value;
        this.currentQuestion.langLink.testLabel = this.currentQuestionTestLabel.value;
      }
    })

    this.qRealSelSub = this.currentQuestionReadSel.valueChanges.subscribe( e => {
      if(this.currentQuestion) {
        this.currentQuestion.readSel = this.currentQuestionReadSel.value;
      }
    });
  }
  
  setSelectedStyleProfile(styleProfile:EStyleProfile) {
    if(this.profile.getProfileOptions().filter(o => o.slug === styleProfile).length){
      this.styleProfileSelector.setValue(styleProfile);
    } else {
      alert(this.lang.tra('test_auth_item_bank_misssing_style_profile'));
    }
  }

  isMultiLingual = () => this.numItemSetLanguages > 1;
  isLang = (langCode: string) => (langCode === this.lang.c())
  isContentEditingDisabled = () => this.personalEditorSettings['isEditorHidden'];

  isQLockAvail() {
    return this.authScopeSettings.getSetting(AuthScopeSetting.ENABLE_Q_LOCK);
  }  
  selectNextQuestion(){
    const question = this.currentQuestion;
    const list = this.questions;
    const i = list.indexOf(this.currentQuestion);
    console.log('next q after', i, list.length)
    if (i > -1){
      this.selectQuestion(list[i+1])
    }
  }

  setActiveItemSetLanguages(languagesProp:string){
    this.activeItemSetLanguages = new Map();
    let itemSetLanguages;
    if (languagesProp){
      itemSetLanguages = JSON.parse(languagesProp);
    }
    else{
      itemSetLanguages = ['en','fr']
    }
    this.numItemSetLanguages = itemSetLanguages.length;
    let isAvailableLangSelected:boolean = false;
    const currentLang = this.lang.c();
    itemSetLanguages.forEach(lang => {
      this.activeItemSetLanguages.set(lang, true);
      if (lang === currentLang){
        isAvailableLangSelected = true;
      }
    });
    // console.log('setActiveItemSetLanguages', this.activeItemSetLanguages)
    if (!isAvailableLangSelected){
      this.lang.setCurrentLanguage(itemSetLanguages[0]);
    }
  }

  isLangEnabled = (langCode:string) => this.activeItemSetLanguages.get(langCode);
  isEnglish = () => this.isLangEnabled('en') && (this.lang.c() !== 'fr');

  selectSection = (partition: IAssessmentPartition) => this.selectedSection = partition;
  isSelectedSection = (partition: IAssessmentPartition) => (this.selectedSection === partition)

  itemBankInfoEditStart(){
    if(!this.styleProfileSelector.value) {
      this.styleProfileSelector.setValue(this.profile.getSelectedStyleProfile());
    }

    if (this.itemBankEditForm){
      this.itemBankInfoEditClose()
    }
    else{
      this.itemBankEditForm = {
        name: new FormControl(this.currentSetName.value),
        description: new FormControl(this.currentSetDescription),
        languages: possibleLanguages.map(langOption => Object({
          id: langOption.id,
          caption: langOption.caption,
          fc: new FormControl( this.activeItemSetLanguages.get(langOption.id) ),
        })),
        styleProfile: this.styleProfileSelector
      }
    }
  }
  itemBankInfoEditSave(){
    const selectedLanguages = [];
    let languages = null;
    this.itemBankEditForm.languages.forEach(lang => {
      if (lang.fc.value){
        selectedLanguages.push(lang.id)
      }
    })
    if (selectedLanguages.length > 0){
      languages = JSON.stringify(selectedLanguages);
    }
    // console.log('selectedLanguages', selectedLanguages)
    this.saveLoadCtrl.saveItemSet(false, {
      name: this.itemBankEditForm.name.value,
      description: this.itemBankEditForm.description.value,
      languages, style_profile: this.profile.getSelectedStyleProfile()
    })
    .then(()=> {
      this.itemBankEditForm = null;
    })
  }
  itemBankInfoEditClose(){
    // if (confirm('Are you sure you want to cancel your changes to the item bank info?')){
    this.itemBankEditForm = null;
    // }
  }
  
  createNewInfoSlide() {
    this.createNewQuestion(ItemType.ITEM, true);
  }

  getInfo(question: IQuestionConfig) {
    return this.itemInfo[question.id];
  }

  getOrder(question: IQuestionConfig) {
    if (this.getInfo(question)) {
      return this.getInfo(question).order;
    }
    return undefined
  }

  getParentId(question: IQuestionConfig) {
    const info = this.getInfo(question);
    if(!info) { 
      return null;
    } else {
      return info.parentId;
    }
  }

  setOrder(question: IQuestionConfig, order:number) {
    if (this.getInfo(question)) {
      this.getInfo(question).order = order;
    }
  }

  setParentId(question: IQuestionConfig, parentId: number) {
    if (this.getInfo(question)) {
      this.getInfo(question).parentId = parentId;
    }
  }

  calcNewQuestionHierarchy(newItemType?:ItemType) {
    if(!this.currentQuestion) {
      if(this.topLevelQuestions.length === 0) {
        return {order: 0};
      }
      return {order: this.getOrder(this.topLevelQuestions[this.topLevelQuestions.length - 1]) }; 
    }
    const parent = this.getParent(this.currentQuestion);
    if (newItemType === ItemType.SEQUENCE){
      if (parent) {
        const selectedOrder = this.getOrder(parent);
        const index = this.topLevelQuestions.indexOf(parent);
        const nextOrder = index >= this.topLevelQuestions.length - 1 ? undefined : this.getOrder(this.topLevelQuestions[index + 1]);
        return {order: nextOrder == null ? (selectedOrder + 1) : (selectedOrder + nextOrder)/2.0};
      } 
      else {
        const selectedOrder = this.getOrder(this.currentQuestion);
        const index = this.topLevelQuestions.indexOf(this.currentQuestion);
        const nextOrder = index >= this.topLevelQuestions.length - 1 ? undefined : this.getOrder(this.topLevelQuestions[index + 1]);
        return {order: nextOrder == null ? (selectedOrder + 1) : (selectedOrder + nextOrder)/2.0};
      }
    }
    else {
      if(this.currentQuestion.type === ItemType.SEQUENCE) {
        const seq = <ISequenceConfig>(this.currentQuestion);
        //Add it to the end of the sequence
        if(seq.children.length === 0) {
          return {order: 0, parentId: seq.id};
        }
        return {order: this.getOrder(seq.children[seq.children.length - 1]) + 1, parentId: seq.id };
      } 
      else if( this.getParentId(this.currentQuestion) ) {
        const selectedOrder = this.getOrder(this.currentQuestion);
        const index = parent.children.indexOf(this.currentQuestion);
        const nextOrder = index >= parent.children.length - 1 ? undefined : this.getOrder(parent.children[index + 1]);
        return {order: nextOrder == null ? (selectedOrder + 1) : (selectedOrder + nextOrder)/2.0, parentId: parent.id};
      } 
      else {
        const selectedOrder = this.getOrder(this.currentQuestion);
        const index = this.topLevelQuestions.indexOf(this.currentQuestion);
        const nextOrder = index >= this.topLevelQuestions.length - 1? undefined : this.getOrder(this.topLevelQuestions[index + 1]);
        return {order: nextOrder == null ? (selectedOrder + 1) : (selectedOrder + nextOrder)/2.0};
      }
    }
  }

  toggleFocusView(isOpen: boolean) {
    if(this.isPsychometricViewEnabled) {
      this.switchToEditorView();
    }
    this.isFocusView = isOpen;
  }

  currentQuestionIsChild() {
    return this.currentQuestion && this.getParentId(this.currentQuestion);
  }

  /**
   * Generates a default label for a new item in the current item bank
   * @param itemType - type of item (e.g. item/sequence/page/text)
   * @returns A label string
   */
  generateDefaultLabel(itemType: ItemType){
    // Generate label for the new question in the item bank
    const prefix = ItemTypeDefs[itemType].caption + " ";
    this.questions.filter( q => q.type === itemType ).forEach(question => {
        const labelNum = parseInt(question.label.substr(prefix.length));
        if (!isNaN(labelNum) && labelNum > this.labelCounter[itemType]) {
          this.labelCounter[itemType] = labelNum;
        }
    });
    this.labelCounter[itemType] ++;
    const label = prefix + this.labelCounter[itemType];
    return label;
  }

  /**
   * Creates a default empty question
   * @param itemType - type of item (e.g. item/sequence/page/text)
   * @param isInfoSlide - whether item is an info slide
   * @param newLabel - string label for the new question
   * @returns An object of type `IQuestionConfig`
   */
  generateDefaultConfig(itemType: ItemType, isInfoSlide: boolean, newLabel: string){
    const newQuestion: IQuestionConfig = {
      content: [],
      isInfoSlide,
      localTaskId: this.labelCounter[itemType],
      label: newLabel,
      meta: {},
      voiceover: {},
      testLabel: "",
      langLink: {
        content: [],
        testLabel: "",
        isInfoSlide,
        localTaskId: -1,
        label: '-',
        langLink: null,
        meta: {},
        voiceover: {},
        type: itemType,
        backgroundImage: createDefaultElement(ElementType.IMAGE),
        bannerImage: createDefaultElement(ElementType.IMAGE),
        bannerSubtitle: <IContentElementText>createDefaultElement(ElementType.TEXT),
        bannerTitle: <IContentElementText>createDefaultElement(ElementType.TEXT),
        bannerOverlay: createDefaultElement(ElementType.IMAGE),
        customButtonPos: CustomButtonPos.AFTER_CONTENT
      },
      type: itemType,
      backgroundImage: createDefaultElement(ElementType.IMAGE),
      bannerImage: createDefaultElement(ElementType.IMAGE),
      bannerSubtitle: <IContentElementText>createDefaultElement(ElementType.TEXT),
      bannerTitle: <IContentElementText>createDefaultElement(ElementType.TEXT),
      bannerOverlay: createDefaultElement(ElementType.IMAGE),
      customButtonPos: CustomButtonPos.AFTER_CONTENT,
      readSelections: null,
      readSelProps: null,
      caption: null,
      bannerHrColor: null,
      bannerSubtitleMarginBottom: null,
      comments: null,
      customButtonIndent: null,
      customNextBgColor: null,
      customNextBold: null,
      customNextFgColor: null,
      points: null,
      ishidePoints: null,
      reqFillEntries: null,
      isReadingSelectionPage: null,
      entryIdCounter: null,
      isReqFill: null,
      reqFillMsg: null,
      readingSelectionCaption: null,
      isReadingSelectionsAlwaysShown: null,
      isStartHalf: null,
      isQuestionnaire: null,
      showBannerHr: null,
      useCustomPrev: null,
      customPrevBgColor: null,
      customPrevFgColor: null,
      customPrevText: null,
      customPrevBold: null,
      useCustomNext: null,
      customNextText: null,
      notes: null,
    };

    return newQuestion;
  }

   /**
   * Creates a new item and navigates to it
   * @param itemType - type of item (e.g. item/sequence/page/text)
   * @param isInfoSlide - whether item is an info slide
   * @param newQuestionConfig - (optional) An already prepared config for the new question (e.g. config derived from clone target during duplication). If not passed, a default config is generated.
   * @returns Config of the new question, including `id` and `versionId` generated from the db
   */
  createNewQuestion(itemType: ItemType, isInfoSlide: boolean= false, newQuestionConfig?) {

    if(this.isCreateItemTypeDisabled(itemType)) {
      console.error('Attempting to create disabled item type')
      return;
    }

    this.saveLoadCtrl.isLoadingQuestion = true;
    return this.saveLoadCtrl.saveCurrentQuestionData().then(()=>{

      // If a config for the new item was passed (e.g. for question duplication), use that
      // Otherwise make default item config from scratch with a default new label
      const newQuestion: IQuestionConfig = newQuestionConfig || this.generateDefaultConfig(itemType, isInfoSlide, this.generateDefaultLabel(itemType));

      const {parentId, order} = this.calcNewQuestionHierarchy(itemType);

      if(itemType === ItemType.SEQUENCE) {
        (<ISequenceConfig>newQuestion).children = [];
      }

      return this.auth.apiCreate(
        this.routes.TEST_AUTH_QUESTIONS,
        {
          question_set_id: this.customTaskSetId,
          question_label: newQuestion.label,
          config: JSON.stringify(newQuestion),
          is_editing: 1,
          last_touched_by: this.auth.getDisplayName(),
          parent_id: parentId,
          order,
          item_type: itemType
        }
        )
        .then(res => {
          newQuestion.id = res.id;
          newQuestion.versionId = res.version_id;
          this.questions.push(newQuestion);
          this.itemInfo[res.id] = {
            order,
            parentId,
            linkedTags: []
          }
          this.saveLoadCtrl.isLoadingQuestion = false;
          this.selectQuestion(newQuestion, false, true);
          this.createNewQuestionSub.next();
          this.currentQuestionLabel.setValue(newQuestion.label);
          this.currentQuestionReadSel.setValue(newQuestion.readSel)
          this.currentQuestionNotes.setValue(newQuestion.notes);

          this.refreshQuestionsView();
          return newQuestion;
        });
      })
  }
  
  getQuestionById = (id: number) => {
    let question: IQuestionConfig;
    this.questions.forEach(_question => {
      if (_question.id === id) {
        question = _question;
      }
    });
    return question;
  }
  getQuestionByLabel = (label: string) => {
    let question: IQuestionConfig;
    this.questions.forEach(_question => {
      if (_question.label === label) {
        question = _question;
      }
    });
    return question;
  }
  selectQuestionById(id: number) {
    let targetQuestion = this.getQuestionById(id);
    if (targetQuestion) {
      this.selectQuestion(targetQuestion);
    }
  }
  selectQuestionByLabel(label: string) {
    let targetQuestion = this.getQuestionByLabel(label);
    if (targetQuestion) {
      return this.selectQuestion(targetQuestion);
    }
    return Promise.reject();
  }
  getCurrentQuestionContent() {
    return this.getQuestionContent(this.currentQuestion);
  }

  getCurrentQuestionScoringConfig() {
    return this.getQuestionScoringInfo(+this.currentQuestion.id)
  }

  getSuggestionsEnabled(){
    return this.authScopeSettings.getSetting(AuthScopeSetting.ENABLE_SUGGESTIONS);
  }

   //If in tracked changes mode, returns the suggestion / "After" state rather than the current / "Before" state.
  getCurrQStateContent(languageType?:string) {
    // Determine if languageType is provided and set default behavior accordingly if language type === current lang view then behave as default
    const useDefaultBehavior = !languageType || languageType === this.lang.c();
    let currentQuestion = useDefaultBehavior?this.getCurrentQuestionContent():languageType==='fr'?this.getCurrentQuestionContent().langLink: this.currentQuestion;
    const isTrackingChanges = currentQuestion?.isTrackingChanges;
    
    // Check if tracking changes and language match and suggestions are enabled; if not, return current content 
    if (!isTrackingChanges || this.lang.c() !== languageType || this.getSuggestionsEnabled()) {
      return currentQuestion;
    } else {
      // Handle different edit view modes
      switch (this.editViewMode) {
        case EditViewMode.BEFORE:
          return currentQuestion;
        case EditViewMode.AFTER:
          return this.itemComponentEdit.suggestion?.state;
        default:
          return this.itemComponentEdit.suggestion?.state || currentQuestion;
      }
    }
  }

  getQuestionContent(q: IQuestionConfig) {
    if (this.isLang('fr')) {
      return q?.langLink;
    }
    return q;
  }

  saveScoringInfoLocal(newScoringInfo){
    const existingInfoIndex = this.scoringInfo?.findIndex(info => info.item_id == +this.currentQuestion.id && info.lang == this.lang.c())
    if (existingInfoIndex){
      this.scoringInfo[existingInfoIndex] = newScoringInfo
    } else {
      this.scoringInfo.push(newScoringInfo)
    }
  }

  getQuestionScoringInfo(qId: number) {
    const existingInfo = this.scoringInfo?.find(info => info.item_id == qId && info.lang == this.lang.c())
    if (existingInfo) {
      return existingInfo
    } else {
      let newInfo: IQuestionScoringInfo = {
        item_id: qId,
        lang: this.lang.c(),
        is_human_scored: false,
        scales: []
      }
      this.scoringInfo.push(newInfo)
      return newInfo;
    }
  }

  getAllPossibleScoringScalesForAssessment() {
    const tags = this.frameworkCtrl.asmtFmrk.tags
    if(!tags) return this.availableScoringCodes;
    let typeSlug = null; 

    tags.forEach(tag => {
      if(tag.slug === 'OSSLT'){ typeSlug = 'OSSLT_SCALE'} 
      else if(tag.slug === 'PJ'){ typeSlug = 'PJ_SCALE' }
    });

    if(!typeSlug) return this.availableScoringCodes;
    return this.availableScoringCodes.filter(sc => sc.type_slug === typeSlug);
    
  }

  getScoringScaleById(id: number) {
    const scale = this.availableScoringCodes.find(code => code.id === id)
    return scale;
  }

  extractQuestionTaskIds() {
    const taskIds: string[] = [];
    this.questions.forEach(question => {
      if (!question.isInfoSlide && question.taskId) {
        taskIds.push(question.taskId);
      }
    });
    return taskIds;
  }
  
  isQuestionRecentlyAdded = (question) => this.recentlyAddedQuestion === question
  
  selectSectionQuestion(questionIdentifier) {
    const question = this.getQuestionByLabel(questionIdentifier.label);
    this.selectQuestion(question);
  }
  isSectionQuestionSelected(questionIdentifier) {
    if (this.currentQuestion) {
      if (this.currentQuestion.label === questionIdentifier.label) {
        return true;
      }
    }
  }

  openQuestionNavigation(questionIdentifier) {
    const question = this.getQuestionForNavigation(questionIdentifier);
    if (!question) throw new Error('openQuestionNavigation, question not found')
    if (this.currentQuestionNavigation && this.currentQuestionNavigation.id === question.id) {
      this.currentQuestionNavigation = null;
    } else {
      this.currentQuestionNavigation = question;
    }
  }

  getQuestionForNavigation(questionIdentifier) {
    this.setQuestionNavigationState(questionIdentifier);
    if (!this.frameworkCtrl.asmtFmrk) return;
    const sectionItems = this.frameworkCtrl.asmtFmrk.sectionItems;
    const questionDetails = this.questions.find(q => q.id === questionIdentifier.id);
    const question = Object.keys(sectionItems).reduce((allSections, sectionId) => {
      const question = sectionItems[sectionId].questions.reduce((prevQ, q) => {
        if (q.label === questionIdentifier.label) {
          if (questionIdentifier.anchorLabel) {
            this.currentQuestionNavigationLabel = new FormControl(questionIdentifier.anchorLabel);
            return q;
          }
          this.currentQuestionNavigationLabel = questionDetails && questionDetails.caption 
            ? new FormControl(`Section ${sectionId} - ${questionDetails.caption}`) 
            : new FormControl(`Section ${sectionId} - ${q.label}`);
          return q
        }
        return prevQ;
      }, null)
      if (question) return question;
      return allSections;
    }, null);
    return question;
  }

  isQuestionSetForNavigation = (questionIdentifier) => questionIdentifier && questionIdentifier.isAnchor;

  isCurrentQuestionNavigationOpen = (questionIdentifier) => this.currentQuestionNavigation && this.currentQuestionNavigation.label === questionIdentifier.label;

  setQuestionNavigationState = (questionIdentifier) => {
    if (questionIdentifier && questionIdentifier.lock) {
      switch (questionIdentifier.lock.type) {
        case 'section':
          this.currentQuestionLockBy = 'section';
          this.currentQuestionLockBySectionId = questionIdentifier.lock.by.sectionId;
          return;
        case 'item':
          this.currentQuestionLockBy = 'item';
          this.currentQuestionLockByItems = questionIdentifier.lock.by.items;
          return;
        case 'choice':
          this.currentQuestionLockBy = 'choice';
          this.currentQuestionLockByEntryId = questionIdentifier.lock.by.entryId;
          this.currentQuestionLockByItem = questionIdentifier.lock.by.item;
          this.currentQuestionLockByChoiceId = questionIdentifier.lock.by.choiceId;
          return;
      }
    }
    this.currentQuestionLockBy = null;
    this.cleanQuestionNavigationState();
  }

  cleanQuestionNavigationState = () => {
    this.currentQuestionLockBySectionId = null;
    this.currentQuestionLockByItems = [];
    this.currentQuestionLockByItem = null;
    this.currentQuestionLockByNewItemLabel = new FormControl('');
    this.currentQuestionLockByChoiceId = null;
    this.currentQuestionLockByEntryId = null;
  }

  trackByIndex = (index: number) => index;

  addQuestionLockByItemLabelToChoiceLock = (fc, currentQuestion) => {
    const foundQ = this.questions.find(q => q.label === fc.value);
    if (foundQ) {
      this.currentQuestionLockByItem = { 
        id: foundQ.id, 
        label: foundQ.label
      };
      fc.setErrors(null);
      return this.setQuestionNavigationAsAnchor(true, currentQuestion);
    }
    fc.setErrors({'incorrect': true});
  }


  addQuestionLockByItemLabel = (fc, currentQuestion) => {
    const foundQ = this.questions.find(q => q.label === fc.value);
    if (foundQ) {
      this.currentQuestionLockByItems = [
        ...this.currentQuestionLockByItems, 
        { 
          id: foundQ.id, 
          label: foundQ.label
        } 
      ];
      fc.setErrors(null);
      fc.setValue('');
      return this.setQuestionNavigationAsAnchor(true, currentQuestion);
    }
    fc.setErrors({'incorrect': true});
  }

  customTestlet:{quadrantId:number, items:any[]};
  addQuestionToTestlet(item:any){
    this.customTestlet.items.push(item);
  }
  removeItemFromCustomTestlet(item:any){
    const testletItems = this.customTestlet.items;
    const index = testletItems.indexOf(item);
    testletItems.splice(index, 1);
  }
  isCustomTestlet(){
    return !! this.customTestlet
  }

  removeQuestionLockyByItemLabel = (index: number, currentQuestion) => {
    this.currentQuestionLockByItems = this.currentQuestionLockByItems.filter((l, i) => i !== index);
    this.setQuestionNavigationAsAnchor(true, currentQuestion);
  }

  setQuestionNavigationAsAnchor(isSetAsAnchor: boolean, currentQuestion) {
    if (this.frameworkCtrl.asmtFmrk) {
      const sectionItems = this.frameworkCtrl.asmtFmrk.sectionItems;
      const updatedSectionItems = Object.keys(sectionItems).reduce((allSections, sectionId) => {
        const questions = sectionItems[sectionId].questions.map(q => {
          if (q.id === currentQuestion.id) {
            const { isAnchor, lock, anchorLabel, ...others } = q;
            if (isSetAsAnchor) {
              return { 
                ...q, 
                anchorLabel: this.currentQuestionNavigationLabel.value,
                isAnchor: true,
                lock: this.getQuestionNavigationLock()
              }
            }
            return others;
          }
          return q;
        })
        return { ...allSections, [sectionId]: { questions } }
      }, {})
      this.frameworkCtrl.asmtFmrk.sectionItems = updatedSectionItems;
    }
  }

  getQuestionNavigationLock() {
    let lock = null;
    if (this.currentQuestionLockBySectionId) {
      return { type: 'section', by: { sectionId: this.currentQuestionLockBySectionId }}
    }
    if (this.currentQuestionLockByItems.length > 0) {
      return { type: 'item', by: { items: this.currentQuestionLockByItems }}
    }
    if (this.currentQuestionLockByChoiceId) {
      return { type: 'choice', by: { 
        item: {
          id: this.currentQuestionLockByItem.id,
          label: this.currentQuestionLockByItem.label
        }, 
        entryId: this.currentQuestionLockByEntryId,
        choiceId: this.currentQuestionLockByChoiceId 
      } }
    }
    return lock;
  }

  isFormQuestionSelected() {
    let isMatchFound = false;
    if (this.currentQuestion) {
      this.frameworkCtrl.asmtFmrk.partitions.forEach(partition => {
        const sectionItems = this.frameworkCtrl.getSectionQuestionsLinear(this.frameworkCtrl.asmtFmrk, partition);
        sectionItems.questions.forEach(questionIdentifier => {
          if (questionIdentifier.id === this.currentQuestion.id) {
            isMatchFound = true;
          }
        });
      });
    }
    return isMatchFound;
  }
  
  addQuestionToTestForm(question: IQuestionConfig) {
    if (this.selectedSection) {
      const sectionItems = this.frameworkCtrl.getSectionQuestionsLinear(this.frameworkCtrl.asmtFmrk, this.selectedSection);
      sectionItems.questions.push({label: question.label, id: +question.id});
      this.recentlyAddedQuestion = question;
    }
  }

  deselectQuestions() {
    this.currentQuestion = undefined;
  }

  isLocked(q: IQuestionConfig) {
    if(!q) {
      return false;
    }
    const parent = this.getParent(q);
    return q.isLocked || (parent && this.isLocked(parent))
  }
  
  resetQuestionState() {
    this.activeQuestionState = {};
    this.suggestionQuestionState = {};
    this.itemEditCtrl.simulateSubmissionRecord = undefined;
    this.itemEditCtrl.expectedAnswers = undefined;
  }

  selectQuestion(targetQuestion: IQuestionConfig, isToggle: boolean = false, noApi: boolean= false): PromiseLike<any> {    
    this.resetQuestionState();
    if (this.saveLoadCtrl.isLoadingQuestion) {
      return Promise.reject();
    }
    if (noApi) {
      this.currentQuestion = targetQuestion;
      this.editingDisabled.setCurrQState( {isLocked: this.isLocked(this.currentQuestion), 
        isTrackingChanges: this.getCurrQTrackChanges()});
      this.questionStateOnLoad = {
        id: targetQuestion.id,
        state: JSON.stringify(targetQuestion)
      };
      return Promise.resolve();
    }
    return new Promise((selectQuestionResolve, selectQuestionReject ) => {
      // console.log('question: ',targetQuestion)
      const targetQuestionId = targetQuestion.id;
      const previousQuestion = this.currentQuestion;
      let previousQuestionState;
      if (previousQuestion && previousQuestion.id === this.questionStateOnLoad.id) {
        previousQuestionState = this.questionStateOnLoad.state;
      }
      if (previousQuestion === targetQuestion) {
        if (isToggle) {
          this.currentQuestion = null;
        }
        return this.saveLoadCtrl.saveQuestionData(previousQuestion, previousQuestionState, false)
        .then(res => selectQuestionResolve(res))
        .catch(e => selectQuestionReject(e));
      }
      this.saveLoadCtrl.isLoadingQuestion = true;
      this.currentQuestion = null;
      this.saveLoadCtrl.currentQuestionRevisions = null;
      // save current work
      return this.saveLoadCtrl.saveQuestionData(previousQuestion, previousQuestionState, false)
      .then(saveRes => {
        return this.loadTargetQuestion(targetQuestion, selectQuestionResolve, selectQuestionReject).then(() =>{
          this.itemDiffCtrl.refreshSuggestions();
          this.selectQuestionSub.next(targetQuestion);
        });
      })
      .catch(e => {
        alert('An error occured while saving the question');
        this.saveLoadCtrl.isLoadingQuestion = false;
        selectQuestionReject(e);
      });
    });
  }

  removeQuestionFromParent(targetQuestion) {
    if(this.getParentId(targetQuestion)) {
      const parentSequence =  this.getParent(targetQuestion);
      const index = parentSequence.children.indexOf(targetQuestion);
      parentSequence.children.splice(index, 1);
      this.setParentId(targetQuestion, null);
    }
  }

  getQuestionTypeCaption(q:IQuestionConfig, isLowerCase: boolean = false) {
    let caption = ItemTypeDefs[q.type].caption;
    if(isLowerCase) {
      caption = caption.toLowerCase();
    }
    return caption;
  }

  removeQuestion(targetQuestion) {
    if (confirm(`Are you sure you want to remove this ${this.getQuestionTypeCaption(targetQuestion, true)}?`)) {
      this.currentQuestion = null;
      return this.auth.apiRemove(
        this.routes.TEST_AUTH_QUESTIONS,
        targetQuestion.id
        )
        .then(res => {

          const removeQ = (q) => {
            let i = this.questions.indexOf(q);
            if (i !== -1) {
              this.questions.splice(i, 1);
            }
          }

          if(targetQuestion.type === ItemType.SEQUENCE) {
            for(const child of (<ISequenceConfig>targetQuestion).children) {
              removeQ(child);
              this.setParentId(child, null);
            }
            targetQuestion.children = [];
          }
          removeQ(targetQuestion);
          this.removeQuestionFromParent(targetQuestion);

          this.refreshQuestionsView();

        })
        .catch(e => {
          this.loginGuard.quickPopup(`An error has occurred. Could NOT archive ${this.getQuestionTypeCaption(targetQuestion, true)}.`);
        });
      }
  }
  async recoverQuestion() {
    const targetQuestion = this.currentQuestion;
    if (confirm(`Are you sure you want to recover this ${this.getQuestionTypeCaption(targetQuestion, true)}?`)) {
      this.currentQuestion = null;
      return await this.auth.apiRemove(
        this.routes.TEST_AUTH_QUESTIONS,
        targetQuestion.id,
        {query: {recoverQuestion: true}}
        )
        .then(res => {;
          this.loginGuard.quickPopup(this.lang.tra('item_recovery_success'));
        })
        .catch(e => {
          this.loginGuard.quickPopup(`An error has occurred. Could NOT recover ${this.getQuestionTypeCaption(targetQuestion, true)}.`);
        });
      }
  }

  openFramework() {
    if (this.editModeItemId) {
      this.editModeItemId = null;
      this.previewCtrl.exitSampleTestForm()      
    }
    setTimeout(()=>{
      this.switchToPsychometricView()
    }, 0)
  }
  
  removeCurrentQuestion() {
    this.toggleManualEdit();
    this.removeQuestion(this.currentQuestion);
  }
  sanitizeQuestionBeforeSave(question: IQuestionConfig, ignoreLangLink: boolean = false) {
    ensureResponseEntryIds(question);
    if (question.langLink && !ignoreLangLink) {
      ensureResponseEntryIds(question.langLink);
    }
  }

  identifyQuestionResponseEntries = (langLink?:boolean) => {
    let question = this.currentQuestion;
    if (langLink){
      question = question.langLink
    }
    return identifyQuestionResponseEntries(question.content);
  }

  isReadOnly = () => this.editingDisabled.isReadOnly();
  
  duplicateCurrentSelection() {
    if (this.currentQuestion.type === ItemType.SEQUENCE){
      this.duplicateCurrentSequence()
    }
    else {
      this.duplicateCurrentQuestion();
    }
  }

  private findCommonPrefix(questionLabels:string[]){
    if (questionLabels.length === 0) {
      return "";
    }
    const strings = questionLabels.sort()
    const firstString = strings[0];
    const lastString = strings[strings.length - 1];
    let prefixLength = 0;
    while (prefixLength < firstString.length && firstString[prefixLength] === lastString[prefixLength]) {
      prefixLength++;
    }
    return firstString.substring(0, prefixLength);
  }

  async duplicateCurrentSequence() {
    
    console.log('currentQuestion', this.currentQuestion)
    // get questions in current sequence
    const targetQuestions = this.currentQuestion.children || []
    // determine common prefix, and determine new suffixes
    const questionLabels = targetQuestions.map(q => q.label)
    const prevPrefix = this.findCommonPrefix(questionLabels)
    // create new sequence (and capture the ID)
    try {
      await this.createNewQuestion(ItemType.SEQUENCE)
      console.log('new seq?', this.currentQuestion)
    }
    catch(e){
      console.log('new seq failed', e)
      alert('Cannot create new sequence')
      throw new Error()
    }

    const sequence = this.currentQuestion
    const newId = this.currentQuestion.id;
    const sequenceLabel = 'P'+newId
    const newPrefix = sequenceLabel+'_'; // todo: this is a bit hard coded for sequences
    this.currentQuestionLabel.setValue(sequenceLabel)
    this.currentQuestion.label = sequenceLabel; // todo: this should not be necessary

    for (let question of targetQuestions){
      const newLabel = newPrefix + question.label.slice(prevPrefix.length);
      this.forceDisableManualEditMode(); // Manual editing must be off to correctly navigate to new question after creating it
      const newQuestionConfig = this.cloneQuestionConfig(question, newLabel);
      await this.createNewQuestion(ItemType.ITEM, false, newQuestionConfig)
      await this.applyScoringConfigToCurrent(question);
      await this.selectQuestion(sequence);
    }
  }

  duplicateCurrentQuestion(newName?:string, parentId?:number) {
    return new Promise((resolve, reject) => {
      let cloneTarget = this.currentQuestion;

      const cloneParams = this.frameworkCtrl.identifySpecialParams(PARAM_SPECIAL_FLAGS.CLONE_LINKER);
      let sourceLabel = cloneTarget.label;
      const cloneSuffixDelim = '/';
      if (sourceLabel) {
        let sourceLabelSplit = sourceLabel.split(cloneSuffixDelim);
        if (sourceLabelSplit.length > 1) {
          sourceLabelSplit.pop();
        }
        sourceLabel = sourceLabelSplit.join(cloneSuffixDelim);
      }
      cloneParams.forEach(param => {
        this.currentQuestion.meta[param.code] = sourceLabel;
      });
      
      let newLabel = sourceLabel;
      for (let i = 1; i < MAX_ITEM_CLONES; i++) {
        if (i === MAX_ITEM_CLONES - 1) {
          alert('Max clones reached for this item --> ' + MAX_ITEM_CLONES);
        }
        newLabel = sourceLabel + cloneSuffixDelim + 'c' + i;
        let isUnused = true;
        this.questions.forEach(question => {
          if (question.label === newLabel) {
            isUnused = false;
          }
        });
        if (isUnused) {
          break;
        }
      }


      this.forceDisableManualEditMode(); // Manual editing must be off to correctly navigate to new question after creating it

      const newQuestionConfig = this.cloneQuestionConfig(cloneTarget, newLabel);
      this.createNewQuestion(ItemType.ITEM, false, newQuestionConfig).then(res => {
        this.applyScoringConfigToCurrent(cloneTarget);
        resolve(null);
      })
      .catch(e => reject(e));
    });
  }

  forceDisableManualEditMode(){
    if (this.isManualEditApplicable()){
      this.editingDisabled.isManualEditingEnabled = false;
    }
  }

  cloneQuestionConfig(sourceQuestion:IQuestionConfig, newLabel:string){
    const nonTransferableProps = ['id', 'versionId', 'localTaskId', 'assetId', 'label'];
    // First get the default config with the correct duplicate label
    const newQuestionConfig = this.generateDefaultConfig(ItemType.ITEM, false, newLabel)
    // Then replace transferable properties with data from the clone target item
    for(let property of Object.getOwnPropertyNames(sourceQuestion)){
      if(!nonTransferableProps.includes(property)){
        newQuestionConfig[property] = _.cloneDeep(sourceQuestion[property]);
      }
    }
    return newQuestionConfig;
  }

  applyScoringConfigToCurrent(sourceQuestion:IQuestionConfig){
    let questionScoringConfig: IQuestionScoringInfo = {
      is_human_scored: this.getQuestionScoringInfo(sourceQuestion.id).is_human_scored,
      scales: this.getQuestionScoringInfo(sourceQuestion.id).scales,
      item_id: this.currentQuestion.id,
      lang: this.getQuestionScoringInfo(sourceQuestion.id).lang,
    }
    this.scoringInfo.push(questionScoringConfig); // todo:this seems like an odd pattern
    this.auth.apiCreate(this.routes.TEST_AUTH_TEST_QUESTION_SCORING_INFO, questionScoringConfig);
  }
  
  duplicateQuestion(question) {
    if (confirm('Are you sure you want to duplicate this question?')) {
      this.selectQuestion(question)
      .then(() => {
        return this.duplicateCurrentQuestion();
      })
      .then(() => {
        this.itemFilterCtrl.updateItemFilter();
      });
    }
  }
  
  activateLinearFormQuestions(){
    if (this.itemFilterCtrl.activeTestFormFilter){
      this.itemFilterCtrl.activeTestFormFilter = null;
      this.itemFilterCtrl.clearItemsFilter();
    }
    else{
      this.itemFilterCtrl.activeTestFormFilter = {
        questions: this.frameworkCtrl.getLinearFormQuestions(),
      }
      this.itemFilterCtrl.updateItemFilter();
    }
    this.frameworkCtrl.scrollToQuestionListing();
    return this.itemFilterCtrl.activeTestFormFilter.questions;
  }

  navigateToCurrentQuestionPage() {
    if (this.currentQuestion) {
      const i = this.questions.indexOf(this.currentQuestion);
      if (i !== -1) {
        this.currentItemListPage = 1 + Math.floor( i / this.itemListLength );
      }
    }
    this.itemFilterCtrl.updateItemFilter();
  }

  switchToPsychometricView() {
    this.editingDisabled.isInEditor = false;
    this.leaveEditingView();
    // console.log('switchToPsychometricView')
    this.saveLoadCtrl.autoSaveQuestionData();
    this.isPsychometricViewEnabled = true;
    if (!this.isPsychometricInited) {
      this.isPsychometricInited = true;
      this.frameworkCtrl.hoistAssessmentFrameworkForEditing();
    }
    this.navigateToCurrentQuestionPage();
  }

  async switchToEditorView() {
    this.editingDisabled.isInEditor = true;
    this.leaveEditingView();
    this.editModeItemId = null;
    this.isPsychometricViewEnabled = false;

    if(this.editingDisabled.isManualEditingEnabled) {
      if(this.itemFilterCtrl.getSideItemList().includes(this.lastEditedQuestion)) {
        this.currentQuestion = this.lastEditedQuestion;
      } else {
        this.toggleManualEdit();
      }
    }
    setTimeout(() => {
      this.itemEditCtrl.scrollToQuestionLeftPanel();
    }, 300);
  }

  leaveEditingView() {
    if(this.isEditing) {
        this.isEditing = false;
        this.itemComponentEdit.selectedEntry = null;
      if(this.itemComponentEdit.usingEditingMode()) {
        return this.saveLoadCtrl.saveSuggestions();
      }
    }
  }

  onClickAcceptAllChanges() {
    this.itemComponentEdit.acceptAllChanges();
  }

  switchToEditingView() {
    this.isPsychometricViewEnabled = false;
    this.isEditing = true;   
    //Don't need to save suggestions unless you're an author in the editing tab
    const saveSugg = !this.itemComponentEdit.usingEditingMode() ? this.saveLoadCtrl.saveSuggestions() : Promise.resolve();
    
    saveSugg.then(() => {
      this.itemDiffCtrl.refreshSuggestions();
    })

  }

  toggleCurrentQuestionLock() {
    return this.toggleQuestionLock(this.currentQuestion)
  }

  toggleQuestionLock(quest:IQuestionConfig) {
    quest.isLocked = !quest.isLocked;
    if(quest.type === ItemType.SEQUENCE) {
      this.refreshQuestionsView(); //Updates ability to drop into this now locked/unlocked sequence.
    }
    if (this.currentQuestion && quest.id==this.currentQuestion.id) {
      this.refreshEditingDisabledCurrQ();
    }
    return this.saveLoadCtrl.saveTargetQuestionData(quest);
  }

  questionDrop(event) {
    let question;
    const fromList = event.previousContainer.data;
    const toList = event.container.data;

    question = fromList[event.previousIndex];
    if(event.container.id.startsWith("sequence-")) {
      this.setParentId(question,+event.container.id.substr("sequence-".length) );
    } else {
      this.setParentId(question, null );
    }
    
    this.util.dropBtwn(event);
    // const currentOrder = this.getOrder(question);
    // console.log(currentOrder);
    
    const newIndex = event.currentIndex;
    
    if(toList.length === 1) {
      this.setOrder(question, 0);
    } else {
      let prevQOrder;
      let nextQOrder;
      if(newIndex === 0) {
        prevQOrder = undefined;
        nextQOrder = this.getOrder(toList[1]);
      } else if(newIndex === toList.length - 1) {
        prevQOrder = this.getOrder(toList[toList.length - 2])
        nextQOrder = undefined;
      } else {
        prevQOrder = this.getOrder(toList[newIndex - 1]);
        nextQOrder = this.getOrder(toList[newIndex + 1]);
      }
  
      if(prevQOrder == null) {
        this.setOrder(question, nextQOrder - 1);
      } else if(nextQOrder == null) {
        this.setOrder(question, prevQOrder + 1);
      } else {
        this.setOrder(question, (prevQOrder + nextQOrder)/2.0);
      }
    }

    this.saveLoadCtrl.isSaving = true;
    this.auth.apiPatch(this.routes.TEST_AUTH_QUESTIONS, question.id, {
      order: this.getOrder(question),
      parent_id: this.getParentId(question)
    }).then(() => {
      this.saveLoadCtrl.isSaving = false;
    })
  } 

  updateTopLevelQuestions() {
    this.topLevelQuestions = this.questions.filter( (q) => {
      return !this.getParentId(q) && (!this.itemFilterCtrl.isFiltering() || 
        this.itemFilterCtrl.filteredQuestions.includes(q) ||
        (q.type === ItemType.SEQUENCE && 
          (<ISequenceConfig>q).children.length > 0) //Assumes children have already been filtered
      );
    }).sort( (a,b)=>{return this.getOrder(a) - this.getOrder(b)});
  }

  getDropListId(seq: ISequenceConfig) {
    return "sequence-" + seq.id;
  } 

  updateSequences() {
    this.sequences = <ISequenceConfig[]>this.questions.filter(q => q.type === ItemType.SEQUENCE);
  }

  updateDropListIds() {
    this.dropListIds = this.sequences.filter( s => !this.isLocked(s)).map( (s: ISequenceConfig) => this.getDropListId(s));
    this.dropListIds.push('item-list');
  }

  refreshQuestionsView() {
    this.updateSequences();
    this.updateDropListIds();
    this.refreshSequenceChildren();
    this.updateTopLevelQuestions();
  }

  currentQuestionIsSequence() {
    return this.currentQuestion && this.currentQuestion.type === ItemType.SEQUENCE;
  }

  refreshSequenceChildren() {
    for(const s of this.sequences){
      s.children = [];
    }
    for(const q of this.questions) {
      if(this.getParentId(q) && (!this.itemFilterCtrl.isFiltering() || this.itemFilterCtrl.filteredQuestions.includes(q))) {
        const p = this.getParent(q)
        if (p){
          p.children.push(q);
        }
        else {
          console.log('seq setup fail', 'id', this.getParentId(q), 'parent', p )
        }
      }
    }
    for(const s of this.sequences) {
      s.children = s.children.sort((a:IQuestionConfig, b: IQuestionConfig) => { return this.getOrder(a) - this.getOrder(b)});
    }
  }

  isCreateItemTypeDisabled(type: ItemType) {
    return false; // type === ItemType.SEQUENCE && (this.currentQuestionIsSequence() || this.currentQuestionIsChild());
  }

  findFirstQuestion() {
    for(const q of this.topLevelQuestions) {
      if(q.type === ItemType.SEQUENCE) {
        const seq = <ISequenceConfig>(q);
        if( seq.children && seq.children.length > 0) {
          return seq.children[0];
        }
      } else {
        return q;
      }
    }
    return undefined;
  }

  getParent(q: IQuestionConfig, ignoreLang: boolean = true) : ISequenceConfig {
    if(!q || !this.getParentId(q)) {
      return undefined;
    } else {
      const res = <ISequenceConfig>this.getQuestionById(+(this.getParentId(q)));
      if(!ignoreLang) {
        if(q.langLink) {
          return res;
        } else {
          return <ISequenceConfig>res.langLink;
        }
      }
      return res;
    }
  }

  getItems() {
    if(!this.questions) {
      return [];
    }
    return this.questions.filter( q => q.type !== ItemType.SEQUENCE);
  }

  setSequenceCollapsed(seq: ISequenceConfig, isCollapsed:boolean) {
    if(seq.isCollapsed === isCollapsed) {
      return;
    }

    seq.isCollapsed = isCollapsed;
    this.saveLoadCtrl.saveTargetQuestionData(seq);
  }

  getReadingSelections(q: IQuestionConfig) {
    if(!q) {
      return [];
    }
    const parent = this.getParent(q, false);
    const parentReadSel = this.getReadingSelections(parent);
    const readSels = q.readSelections || [];
    return parentReadSel.concat(readSels);
  }

  getNumParentReadSels(q: IQuestionConfig) {
    const parent = this.getParent(q, false);
    return this.getReadingSelections(parent).length;
  }

  isOwnReadSel(q: IQuestionConfig, index: number) {
    return index >= this.getNumParentReadSels(q);     
  }

  getCurrentReadingSelections() {
    return this.getReadingSelections(this.getCurrentQuestionContent());
  }

  getVisibleReadingSelections = (q: IQuestionConfig) => {
    return this.getReadingSelections(q).filter(r => this.isReadSelVisible(q, r));
  }

  generateDefaultReadSelProps() { 
    return {
      isVisible: true
    }
  }

  isReadSelVisible(q: IQuestionConfig, readSel: string) {
    return q.readSelProps ? (q.readSelProps[readSel] ? q.readSelProps[readSel].isVisible : true) : true;
  }

  isCurrentReadSelVisible(readSel: string) {
    return this.isReadSelVisible(this.getCurrentQuestionContent(), readSel);
  }

  getReadSelIndex(q: IQuestionConfig, index: number) {
    return index - this.getNumParentReadSels(q);
  }

  addTag(tag: IItemTag) {
    if(this.getCurrLinkedTags().filter(t => t.id === tag.id).length) {
      return;
    }

    this.getCurrLinkedTags().push(tag);
    this.tagFc.setValue("");//Refreshes the filtered list
    this.auth.apiCreate(this.routes.TEST_AUTH_ITEM_TAG_LINK, {
      item_id: this.currentQuestion.id,
      tag_id: tag.id
    }).then( () => {

    })
  }

  deleteTag(tag: IItemTag) {
    const index = this.getCurrLinkedTags().findIndex( t => t.id === tag.id);
      
    if(index === -1) {
      return;
    }

    this.getCurrLinkedTags().splice(index, 1);
    
    this.tagFc.setValue(this.tagFc.value);//Refreshes the filtered list
    
    this.auth.apiRemove(this.routes.TEST_AUTH_ITEM_TAG_LINK, tag.id, { query: {
      item_id: this.currentQuestion.id,
      tag_id: tag.id}
    }).then ( () => {
    })
  }

  refreshEditingDisabledCurrQ() {
    this.editingDisabled.setCurrQState({isLocked: this.isLocked(this.currentQuestion), isTrackingChanges: this.getCurrQTrackChanges()});
  }

  getCurrQTrackChanges() {
    return this.getCurrentQuestionContent()?.isTrackingChanges;
  }

  getCurrQIsEditing() {
    return this.getCurrentQuestionContent()?.isEditing;
  }

  toggleCurrentQuestionIsEditing() {
    const currQContent = this.getCurrentQuestionContent();
    currQContent.isEditing = !currQContent.isEditing;
    this.saveLoadCtrl.saveCurrentQuestionData(true);
  }

  toggleCurrentQuestionTrackChanges() {  



    const currQContent = this.getCurrentQuestionContent();

    const doToggle = () => {
      currQContent.isTrackingChanges = !currQContent.isTrackingChanges;
      if(!currQContent.isTrackingChanges) {
        currQContent.isEditing = false;
      }
      this.refreshEditingDisabledCurrQ();
      
      this.saveLoadCtrl.saveCurrentQuestionData(true).then(() => {
        if(currQContent.isTrackingChanges) { //Create a new suggestion state when entering tracked changes mode
          this.saveLoadCtrl.createSuggestionState();
        }
      });
    }

    if(currQContent.isTrackingChanges) { //trying to exit tracking changes
      const markResponded = () => {
        if(this.itemComponentEdit.suggestion) {            
          this.auth.apiPatch(this.routes.TEST_AUTH_SUGGESTIONS, this.itemComponentEdit.suggestion.id, {is_responded: 1}).then(() => {
            this.itemComponentEdit.suggestion = undefined;
          }) 
        }
        doToggle();
      }

      const entryIds = this.itemComponentEdit.entryIdMappedToDiffs.keys();

      for(const entryId of entryIds) {
        const diffs = this.itemComponentEdit.getViewableDiffs(entryId);
        if(diffs?.length) {
          this.loginGuard.confirmationReqActivate({
          confirm: () => {
            markResponded();
          }, 
          close: () => {
            //Do nothing.
          }, 
          caption: 'You have unresolved edits remaining. If you leave Track Changes mode, you will lose them. Continue?'});
          return;
        }
      }
      markResponded();
    } else {
      const showOtherLangPopup  = (lang) => {
        this.loginGuard.quickPopup(`Changes are already being tracked in the ${lang} version of this question. 
        Tracking changes is only enabled for one language at a time.`);
      }
      
      if(this.lang.c() === 'fr' && this.currentQuestion.isTrackingChanges) {
        showOtherLangPopup('English')
        return;
      } else if(currQContent.langLink?.isTrackingChanges) {
        showOtherLangPopup('French')
        return;
      }
      doToggle();
    }
  }

  isSuggestionMode(question?: IQuestionConfig) {
    if(this.authScopeSettings.getSetting(AuthScopeSetting.DISABLE_REVIEW_EDIT_TAB)) {
      return false;
    }
    if(!question) {
      question = this.getCurrentQuestionContent();
    }

    const usingEditingMode = this.itemComponentEdit.usingEditingMode();
    const inSuggestionTab = (!usingEditingMode && !this.isEditing) || (usingEditingMode && this.isEditing);

    return question?.isTrackingChanges && inSuggestionTab;
  }

  reloadCurrentQuestion() {
    this.loadTargetQuestion(this.currentQuestion);
  }

  isEditActivationPending(){ return this.editingDisabled.isEditActivationPending() }
  isManualEditApplicable(){ return this.editingDisabled.isManualEditApplicable() }
  async activateManualEditing(){
    this.editingDisabled.isManualEditingEnabled = true;
    if (this.currentQuestion){
      this.indicateQuestionForEditing(this.currentQuestion.id);
    }
  }
  async finishManualEditing(){
    const description = prompt('(optional) You can provide a brief description of the changes you have made since you started editing:')
    await this.saveLoadCtrl.saveCurrentQuestionData(false, description) // save current question
    this.editingDisabled.isManualEditingEnabled = false;
  }

  lastEditedQuestion: IQuestionConfig;
  async toggleManualEdit(){
    if (!this.saveLoadCtrl.isSaving){
      if (this.isEditActivationPending()){
        await this.activateManualEditing()
        this.lastEditedQuestion = this.currentQuestion;
      }
      else {
        await this.finishManualEditing()
      }
    }
  }

  expectedAnswers:Map<number, ExpectedAnswerSummary> = new Map();
  async loadExpectedAnswers(){
    const itemSetEA:{itemExpectedAnswers:ExpectedAnswerSummary[]} = await this.auth.apiGet(this.routes.ITEM_SET_EXPECTED_ANS, this.customTaskSetId, {
      query: {
        lang: this.lang.c(),
      }
    })
    this.expectedAnswers = new Map();
    for (let ea of itemSetEA.itemExpectedAnswers){
      this.expectedAnswers.set(ea.item_id, ea);
    }
  }
  hasExpectedAnswer(item_id:number){
    const ea = this.expectedAnswers.get(item_id)
    if (ea){
      return (ea.lang === this.lang.c())
    }
    return false;
  }

  getSupportedLangs(){
    return Array.from(this.activeItemSetLanguages.keys());
  }

  /**
   * 
   * @param langs Languages for which you want the expected answers to
   * @returns a promise of a map of languages to expected answers of items in current item set
   */
  async getLangExpectedAnswerMap(langs?:string[]): Promise<Map<string, Map<number, ExpectedAnswerSummary>>>{
    const genExpectedAnsMap = async (lang) => {
      const expectedAnswers = new Map<number, ExpectedAnswerSummary>();
      const itemSetEA:{itemExpectedAnswers:ExpectedAnswerSummary[]} = await this.auth.apiGet(this.routes.ITEM_SET_EXPECTED_ANS, this.customTaskSetId, {
        query: {
          lang,
        }
      });
      for (let ea of itemSetEA.itemExpectedAnswers){
        expectedAnswers.set(ea.item_id, ea);
      }
      return expectedAnswers
    }
    
    const supportedLangsResponseMap = new Map<string, Map<number, ExpectedAnswerSummary>>();
    for(let lang of langs){
      try{
        const ans = await genExpectedAnsMap(lang);
        supportedLangsResponseMap.set(lang, ans);
      } catch (e){
        console.log(e);
      }
    }
    return supportedLangsResponseMap
  }

  getEAWeight(item_id:number){
    const ea = this.expectedAnswers.get(item_id)
    return ea.weight
  }
  getEAOptions(item_id:number){
    const ea = this.expectedAnswers.get(item_id)
    return ea.correct_responses || []
  }
  
  private replaceQuestionProps(questionContent: IQuestionConfig, targetQuestion: IQuestionConfig){
    for (let member in targetQuestion) {
      delete targetQuestion[member];
    }
    for (let member in questionContent) {
      targetQuestion[member] = questionContent[member];
    }
  }
  private sourceQuestionPropsFromDbRecord(questionRecord:any, targetQuestion: IQuestionConfig){
    targetQuestion.label = questionRecord.question_label;
    targetQuestion.id = questionRecord.id;
    targetQuestion.versionId = questionRecord.version_id;
    targetQuestion.type = questionRecord.item_type;
    this.setParentId(targetQuestion, questionRecord.parent_id);
    if (questionRecord.updated_on){
      targetQuestion.updated_on = questionRecord.updated_on;
    }
  }
  private mapQuestionFormControls(){
    this.currentQuestionLabel.setValue(this.currentQuestion.label);
    this.currentQuestionReadSel.setValue(this.currentQuestion.readSel)
    this.currentQuestionNotes.setValue(this.currentQuestion.notes);
  }
  private initDefaultQuestionProps(targetQuestion: IQuestionConfig){
    if(targetQuestion.type === ItemType.SEQUENCE){
      this.refreshSequenceChildren();
    }

    if (!targetQuestion.voiceover) {
      targetQuestion.voiceover = {};
    }
    if (targetQuestion.langLink && !targetQuestion.langLink.voiceover) {
      targetQuestion.langLink.voiceover = {};
    }

    if(!targetQuestion.backgroundImage) {
      targetQuestion.backgroundImage = createDefaultElement(ElementType.IMAGE);
    }
    if(!targetQuestion.bannerImage) {
      targetQuestion.bannerImage = createDefaultElement(ElementType.IMAGE);
    }
    if(!targetQuestion.bannerSubtitle) {
      targetQuestion.bannerSubtitle = <IContentElementText>createDefaultElement(ElementType.TEXT);
    }

    if(!targetQuestion.bannerTitle) {
      targetQuestion.bannerTitle = <IContentElementText>createDefaultElement(ElementType.TEXT);
    }

    if(!targetQuestion.bannerOverlay) {
      targetQuestion.bannerOverlay = createDefaultElement(ElementType.IMAGE);
    }

    if(!targetQuestion.customButtonPos) {
      targetQuestion.customButtonPos = CustomButtonPos.AFTER_CONTENT;
    }

    targetQuestion.customPrevBgColor = targetQuestion.customPrevBgColor || DEF_CUSTOM_BTN_BG_COLOR;
    targetQuestion.customNextBgColor = targetQuestion.customNextBgColor || DEF_CUSTOM_BTN_BG_COLOR;
    targetQuestion.customPrevFgColor = targetQuestion.customPrevFgColor || DEF_CUSTOM_BTN_FG_COLOR;
    targetQuestion.customNextFgColor = targetQuestion.customNextFgColor || DEF_CUSTOM_BTN_FG_COLOR
    ;
  }

  async preserveQuestionStateOnLoad(targetQuestion: IQuestionConfig){
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.questionStateOnLoad = {
          id: targetQuestion.id,
          state: JSON.stringify(targetQuestion)
        };
        this.saveLoadCtrl.isLoadingQuestion = false;
        resolve(true);
      }, 100);
    })
  }

  async indicateQuestionForEditing(questionId: number, isSkipped?:boolean){
    if (isSkipped){
      return false;
    }
    return this.auth.apiPatch(
      this.routes.TEST_AUTH_QUESTIONS,
      questionId,
      {
        is_editing: 1,
        last_touched_by: this.auth.getDisplayName()
      }
    )
  }

  private checkIfEditingOnLoad(){
    if (this.isReadOnly()){
      return false;
    }
    if (this.editingDisabled.isManualEditingEnabled && !this.isPsychometricViewEnabled){
      return false;
    }
    return true;
  }

  async loadTargetQuestion(targetQuestion: IQuestionConfig, resolve?, reject?) {
    try {
      const questionRecord = await this.auth.apiGet(this.routes.TEST_AUTH_QUESTIONS, targetQuestion.id);
      const questionContent:IQuestionConfig = JSON.parse(questionRecord.config);
      questionContent.question_set_id = questionRecord.question_set_id;
      this.replaceQuestionProps(questionContent, targetQuestion);
      this.sourceQuestionPropsFromDbRecord(questionRecord, targetQuestion);
      this.initDefaultQuestionProps(targetQuestion);
      this.currentQuestion = targetQuestion;
      if(this.getParentId(this.currentQuestion)) {
        const parent = this.getParent(this.currentQuestion);
        parent.isCollapsed = false; //Don't use setSequenceCollapsed because we don't care about saving the collapsed state here.
      }
      this.refreshEditingDisabledCurrQ();            
      this.mapQuestionFormControls()
      this.editingDisabled.isManualEditingEnabled = !this.whiteLabel.getSiteFlag('PREVENT_AUTO_EDIT');
      
      await Promise.all([
        this.preserveQuestionStateOnLoad(targetQuestion),
        this.indicateQuestionForEditing(targetQuestion.id, !this.checkIfEditingOnLoad()),
      ])
      if(resolve){ resolve(null); }
    }
    catch (e) {
      alert('An error occured while loading the question');
      if(reject) { reject(e); }
    };
  }

  getAuthQuestionState() {
    if(!this.getCurrQTrackChanges()) {
      return this.activeQuestionState;
    }

    switch(this.editViewMode) {
      case EditViewMode.BEFORE:
        return this.activeQuestionState;
      case EditViewMode.AFTER:
      default:
        return this.suggestionQuestionState;
    }
  }
  
  setEditViewMode(m: EditViewMode) {
    this.editViewMode = m;
    this.itemDiffCtrl.refreshSuggestions(); 
    //Refreshes the suggestion state, 
    //which updates the suggestionStateCopy, 
    //this triggers ngononinit of element-configs,
    //which triggers update to change counter to refresh render
    //which is required at least for grouping elements to render properly in editing mode. 
  }

  getNumPendingGraphicReqs(questionId?:number){
    if (!questionId) questionId = this.currentQuestion.id;
    const lang = this.lang.c();
    const numPending = this.pendingGraphicReqCountByQuestion[questionId]?.[lang]
    return numPending;
  }

  /**
   * Increase or decrease the pending graphic request count of the current question
   * @param shift - pass +1 to increase and -1 to decrease by one
   * @param inputLang - provide language, otherwise uses current one
   */
  shiftPendingGraphicReqCount(shift:number, inputLang?:string){
    const lang = inputLang || this.lang.c()
    const qId = this.currentQuestion.id;
    if (!this.pendingGraphicReqCountByQuestion[qId]) this.pendingGraphicReqCountByQuestion[qId] = {}
    const currentCount = this.pendingGraphicReqCountByQuestion[qId][lang] || 0
    this.pendingGraphicReqCountByQuestion[qId][lang] = currentCount + shift
  }

  /** Set the pending grapgic req count to the passed value for the current question and language */
  setPendingGraphicReqCount(newCount:number){
    const lang = this.lang.c();
    const qId = this.currentQuestion.id;
    if (!this.pendingGraphicReqCountByQuestion[qId]) this.pendingGraphicReqCountByQuestion[qId] = {}
    this.pendingGraphicReqCountByQuestion[qId][lang] = newCount;
  }  

  async validateAgainstScoringMatrix(){
    const elements = this.getElementsBeingValidatedAgainstScoringMatrix();
    let combinations = [];
    if(elements.length === 1){//! We can change this when implementation for multiple elements is added
      combinations = generatePossibleElementCombinations(elements[0]);
      const combChunks = _.chunk(combinations, 32);
      for (const combChunk of combChunks) {
        await this.auth.apiPatch(
          this.routes.SCORE_MATRIX_VALIDATION,
          this.currentQuestion.id,
          { combinations: combChunk, lang: this.lang.c() }
        );
    }
    }
    this.itemEditCtrl.getAllExpAnswer();
  }
  getElementsBeingValidatedAgainstScoringMatrix(strict = true): any[] {
    const elements = [];
    const item = this.getCurrentQuestionContent();
    const entryOrder = item?.entryOrder;
    for (let i = 0; i < entryOrder.length; i++) {
      const entryId = entryOrder[i];
      const targetElem = deepFind(item, 'entryId', entryId);
    
      // Check if the target element has a scoreMatrix and it is confirmed
      if (targetElem && targetElem.scoreMatrix) {
        if ((!targetElem.scoreMatrix.isConfirmed || !targetElem.scoreMatrix.isUpdated)  && strict) {
          return [];
        } else {
          elements.push(targetElem);
        }
      } else {
        // Return empty if no target element or scoreMatrix is found
        return [];
      }
    }

    return elements
  }
}
