import * as _ from 'lodash';
import { ElementType, IQuestionConfig } from "src/app/ui-testrunner/models";
import { ItemSetFrameworkCtrl } from "../../item-set-editor/controllers/framework";
import { ItemBankCtrl } from "../../item-set-editor/controllers/item-bank";
import { TestFormConstructionMethod } from "../../item-set-editor/models/assessment-framework";
import { AuditQuestionScope } from "../data/audits";
import { InputFormat } from 'src/app/ui-testrunner/element-render-input/model';
import { getQuestionContent } from './content-analysis';

type PromiseQuestionList = Promise<IQuestionConfig[]>

export interface IContext {
    lang: string,
    itemBankCtrl: ItemBankCtrl, // todo: highly stateful
    frameworkCtrl: ItemSetFrameworkCtrl, // todo: highly stateful
}

export const getQuestionsByScope = async (questionScope: AuditQuestionScope, ctx:IContext, languageSensitive:boolean = true) => {
  switch (questionScope){
    case 'ITEMS_SCORED'           : return getItemsScored(ctx);
    case 'ITEMS_SCORED_MINUS_FT'  : return getItemsScoredMinusFT(ctx);
    case 'ITEMS_SCORED_MINUS_HS'  : return getItemsScoredMinusHumanScored(ctx);
    case 'ITEMS_HUMAN_SCORED'     : return getItemsHumanScored(ctx);
    case 'ITEMS_SURVEY'           : return getItemsSurvey(ctx);
    case 'SCREENS_NONSCORED'      : return []; // todo
    case 'SCREENS_PASSAGES'       : return getScreensPassages(ctx);
    case 'SCREENS_NON_PASSAGES'   : return getNonReadingPassageItems(ctx);
    case 'SCREENS_EXCLUDE_DISABLED_TESTLETS': return getScreens(ctx, questionScope, languageSensitive)
    case 'ITEM_BANK'              : return ctx.frameworkCtrl.itemBankCtrl.getItems().map(q => getQuestionByLang(q, ctx.lang));
    case 'ITEM_BANK_SCORED'       : return ctx.frameworkCtrl.itemBankCtrl.getItems().filter(q => !q.isReadingSelectionPage && !q.isQuestionnaire).map(q => languageSensitive?getQuestionByLang(q, ctx.lang):q);
    case 'PASSAGE_ITEMS'       : return getPassageTestletItems(ctx);
    default:
    case 'SCREENS'                : return getScreens(ctx, questionScope,languageSensitive);
  }
  // Made sceens language sensitive since the rest depend on it, right now everything will be language sensitive
} 
// todo need to make it so that they aren't all dependent on SCREENS as they all use getScreens to get their questions
// todo and go from there some require item bank but item bank can benifit from the additional filtering and can help to string filtering together

export const extractTestDesignScopedquestion = async (ctx:IContext, questionScope?:string) => {         
  
  let {lang, itemBankCtrl, frameworkCtrl} = ctx;

  const asmtFmrk = frameworkCtrl.asmtFmrk;

  let questionsMap: Map<number, IQuestionConfig> = new Map();
  switch(asmtFmrk.testFormType){
    case TestFormConstructionMethod.TLOFT:
      questionsMap = getTloftQuestions(ctx, questionScope);
      break;
    case TestFormConstructionMethod.MSCAT:
      frameworkCtrl.panelCtrl.getAllMsCatQuestions(true).map(q => questionsMap.set(q.id, q));
      break;
    default:
    case TestFormConstructionMethod.LINEAR:
      questionsMap = getLinearFormQuestions(ctx)
      break;
    // case TestFormConstructionMethod.BUCKETS:
  }

  // console.log(Array.from(questionsMap))
  return {questionsMap, lang}
} 

// get all tloft questions
const getTloftQuestions = (ctx:IContext, questionScope?:string): Map<number, IQuestionConfig> => {
  let { itemBankCtrl, frameworkCtrl} = ctx;
  const asmtFmrk = frameworkCtrl.asmtFmrk;
  const questionsMap: Map<number, IQuestionConfig> = new Map();
  const prePostAmbleQid: Set<number> = new Set();
  const excludeDisabled = questionScope === 'SCREENS_EXCLUDE_DISABLED_TESTLETS'? true : false;
  // build prepostamble list
  const storePrePostAmbleByLabel = (qLabel:string) => {
    const q = (itemBankCtrl.getQuestionByLabel(qLabel));
    if (q){ prePostAmbleQid.add(+q.id) }
  }
  asmtFmrk.partitions.forEach(partition => {
    partition?.preambleLabelList?.forEach(qLabel => storePrePostAmbleByLabel(qLabel) );
    partition?.postambleLabelList?.forEach(qLabel => storePrePostAmbleByLabel(qLabel) );
  })

  const questionIds = _.flattenDeep(
    asmtFmrk.testlets.filter(testlet => !excludeDisabled || !testlet.isDisabled).map(
      testlet => testlet.questions.map(question => question.id) 
    )
  );

  questionIds?.map(async(id) => {
    if(!prePostAmbleQid.has(+id)) {
      let question = itemBankCtrl.getQuestionById(+id);
        if(!question){
          question = await itemBankCtrl.saveLoadCtrl.loadDocument(new Map(), +id, true); // forcing outermost structure
        } 
        questionsMap.set(+id, question); 
        // if(!this.isRejectedItem(question)) // tood: we shouldnt be excluding rejected items except through explicit item scopes, commenting this out for now, and hopefully the original writer can circle back onto this
    }
  })

  return questionsMap
}

const getMSCATQuestions = async (ctx:IContext): Promise<Map<number, IQuestionConfig>>  => { // todo: is this assuming msCAT?
  let {lang, itemBankCtrl, frameworkCtrl} = ctx;
  const asmtFmrk = frameworkCtrl.asmtFmrk;
  let questionsMap: Map<number, IQuestionConfig> = new Map();
  const prePostAmbleQid: Set<number> = new Set();
  
  const testForms = await frameworkCtrl.publishingCtrl.getTestForms();
  for(const testForm of testForms){
    const form = JSON.parse(testForm);
    const {lang: testLang, questionDb, sections}  = form
    lang = testLang;
    //build pre-post amble Set
    sections.forEach(section => {
      if(section?.preambleList) section.preambleList?.forEach(qId => prePostAmbleQid.add(+qId))  ;
      if(section?.postambleList) section.postambleList?.forEach(qId => prePostAmbleQid.add(+qId)) ;
    })
    Object.keys(questionDb).map( async (qId:string | number) => {
      if(!prePostAmbleQid.has(+qId)) {
        let question = itemBankCtrl.getQuestionById(+qId);
        if(!question){
          question = await itemBankCtrl.saveLoadCtrl.loadDocument(new Map(), +qId, true); // forcing outermost structure
        } 
        questionsMap.set(+qId, question);
        // if(!this.isRejectedItem(question)) 
      }
    });

  }
  return questionsMap
}

const getLinearFormQuestions = (ctx:IContext): Map<number, IQuestionConfig> => {
  const questionsMap: Map<number, IQuestionConfig> = new Map();
  let { itemBankCtrl, frameworkCtrl} = ctx;
  const asmtFmrk = frameworkCtrl.asmtFmrk;
  const sections = Object.keys(asmtFmrk.sectionItems);
  sections.forEach(section =>{
    asmtFmrk.sectionItems[section].questions?.forEach(question =>{
      questionsMap.set(question.id,itemBankCtrl.getQuestionById(question.id));
    })
  });
  return questionsMap
}


// fetches all the screens (which includes items)
export const getScreens = async (ctx:IContext, questionScope?:string, translate:boolean = true):PromiseQuestionList => {
  const {questionsMap} = await extractTestDesignScopedquestion(ctx, questionScope);
  return Array.from(questionsMap.values()).filter(q=> !!q).map(q => translate?getQuestionByLang(q, ctx.lang):q); // ensure question exists then if it needs to be translated
}

// fetches all the questions that are not reading selection pages and not questionnaires
const getItemsScored = async (ctx:IContext):PromiseQuestionList => {
  const questions = await getScreens(ctx);
  return questions.filter(q => !q.isReadingSelectionPage && !q.isQuestionnaire)
}

// fetches all the questions that are not reading selection pages
const getNonReadingPassageItems = async (ctx:IContext):PromiseQuestionList => {
  const questions = await getScreens(ctx);
  return questions.filter(q => !q.isReadingSelectionPage)
}

// fetches all the screens that are not scored
const getScreensNonscored = async (ctx:IContext):PromiseQuestionList => {
  const itemScoredQuestions = await getItemsScored(ctx);
  const itemScoredQIds = new Set([...itemScoredQuestions.map(q => +q.id)]);
  const screens = await getScreens(ctx);
  return screens.filter(screen => !itemScoredQIds.has(+screen.id) )
}

// fetches all scored questions which do not have a field trial flag set in their meta data
const getItemsScoredMinusFT = async (ctx:IContext):PromiseQuestionList => {
  const itemScoredQuestions = await getItemsScored(ctx);
  return itemScoredQuestions.filter(q => !q.meta || (q.meta && !q.meta['FT'])); // todo: this prop is not actually designated as a universal embedded field trial flag, but it is sort of being used as if it is, this might be a good thing to store in the style profile
}

// fetches all questions that look like they should be human scored
const getItemsHumanScored = async (ctx:IContext):PromiseQuestionList => {
  const itemScoredQuestions = await getItemsScored(ctx);
  const items = [];
  for(const item of itemScoredQuestions){
    const scoringConfig = ctx.itemBankCtrl.getQuestionScoringInfo(+item.id);
    const qContent =  getQuestionContent(item);
    let containsTextBox = false // todo: this is a sub-optimal approach, assumes text is not scored directly (sometimes it is)
    qContent.forEach(content => {
      if(content.elementType == ElementType.INPUT && content.format == InputFormat.TEXT){
        containsTextBox = true
      }
    });
    if(containsTextBox || scoringConfig.is_human_scored){
      items.push(item)
    }
  }
  return items;
}

// fetches all scored questions that are not human scored
const getItemsScoredMinusHumanScored = async (ctx:IContext):PromiseQuestionList => {
  const itemsHS = await getItemsHumanScored(ctx);
  const itemsHSIds = new Set([...itemsHS.map(q => +q.id)]);
  const itemsScored = await getItemsScored(ctx);
  return itemsScored.filter(item => !itemsHSIds.has(+item.id) )
}

// fetches all screens that are reading selection pages
const getScreensPassages = async (ctx:IContext):PromiseQuestionList => {
  const screens = await getScreens(ctx);
  return screens.filter(screen => screen.isReadingSelectionPage);
}

// fetches all questions that are questionnaires, or if the assessment framework in context is a questionnaire assessment, it fetches all questions
const getItemsSurvey = async (ctx:IContext):PromiseQuestionList => {
  const questions = await getScreens(ctx);
  if (ctx.frameworkCtrl.asmtFmrk.isQuestionnaireAssessment){ // todo: this might still be too wide
    return questions;
  }
  return questions.filter(q => q.isQuestionnaire)
}

const getPassageTestletItems = async (ctx:IContext):PromiseQuestionList => {
  const {frameworkCtrl, itemBankCtrl} = ctx;
  const sections = frameworkCtrl.asmtFmrk.sectionItems;
  const assesmentType = frameworkCtrl.testFormConstructionMethod.value;
  const questions:IQuestionConfig[] = [];
  const questionIds = new Set<IQuestionConfig>();

  // Helper function that returns populates the set of questions to ensure unique values
  const getPassageItems = (_questions: {id:number}[], ) => {
    let isPassageGroup = false;
    let groupQuestions = []
    _questions.forEach(question =>{
      let retrievedQuestion: IQuestionConfig;
      try{
        retrievedQuestion = getQuestionByLang(itemBankCtrl.getQuestionById(question.id), ctx.lang); // this has to be language sensitive as read selection depends on language
      } catch{
        console.error("Could not retrieve question with ID " + question.id + " for audit");
      }
      if(retrievedQuestion){
        groupQuestions.push(retrievedQuestion);
        if(retrievedQuestion.isReadingSelectionPage){
          isPassageGroup = true;
        }
      }
    });
    if(isPassageGroup){
      groupQuestions.forEach(q => {
        if(!questionIds.has(q.id)){
          questions.push(q);
          questionIds.add(q.id);
        }
        });
    } 
  }
  switch(assesmentType){
    case TestFormConstructionMethod.MSCAT:
    case TestFormConstructionMethod.TLOFT:
      const testlets = frameworkCtrl.asmtFmrk.testlets;
      testlets.forEach(testlet => getPassageItems(testlet.questions.map(t => {return {id: t.id}})));
      break;
    default:
    case TestFormConstructionMethod.LINEAR:
      Object.values(sections).forEach(section => {
        getPassageItems(section.questions);
      })
    // case TestFormConstructionMethod.BUCKETS:
  }
  return Array.from(questions);
}

export const getQuestionByLang = (question: IQuestionConfig, lang?): IQuestionConfig => {
  if(lang !== 'en'){
    return {...question.langLink, id: question.id, meta: question.langLink.meta?.size ? question.langLink.meta:question.meta};
  }
  return question ;
}






