import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { LangService } from 'src/app/core/lang.service';

enum ELIGIBILITY_STATUS {
  FIRST_TIME_ELIGIBLE = 1,
  PREVIOUSLY_ELIGIBLE = 2
}

enum OSSLT_OUTCOMES {
  PENDING = 0,
  SUCCESSFAUL = 1,
  NOT_YET_SUCCESSFUL = 2,
  ABSENT = 3,
  OSSLC = 4,
  DEFERRED = 5,
  EXEMPTED = 6,
  WITHHELD = 10,
  NOT_SUFFICIENT_EVIDENCE = 11
}

enum OTHER_SCALE_SCORE {
  ALT = 'ALT'
}
@Injectable({
  providedIn: 'root'
})
export class csvOSSLTResultDataService {

  constructor(private lang: LangService) { }

  /**
   * Generate the osslt export csv data
   * @param data Students data
   * @param {boolean} isVersion2 version 2 applies to TW end date after July 01 2024
   * @returns student records to be displayed on CSV
   */
  getOSSLTResultData(data:any, isVersion2:boolean = false) {
    let records = [];
    data.forEach(record => {

      // Modify SASN
      if (record.SASN === null) {
        record.SASN = '#'
      }

      // Modify EligibilityStatus
      if (record.EligibilityStatus) {
        if (record.EligibilityStatus == ELIGIBILITY_STATUS.FIRST_TIME_ELIGIBLE) {
          record.EligibilityStatus = this.lang.tra("sdc_osslt_eligstat_1")
        }
        else if (record.EligibilityStatus == ELIGIBILITY_STATUS.PREVIOUSLY_ELIGIBLE) {
          record.EligibilityStatus = this.lang.tra("sdc_osslt_eligstat_2")
        } else {
          record.EligibilityStatus = ''
        } 
      } else {
        record.EligibilityStatus = ''
      }

      // Modify HasStarted
      if (record.HasStarted) {
        record.HasStarted = this.lang.tra("lbl_yes")
      } else {
        record.HasStarted = this.lang.tra("lbl_no")
      }

      // Modify HasSubmitted
      if (record.HasSubmitted == 1 || (record.HasReport && record.ta_closed_on)) {
        record.HasSubmitted = this.lang.tra("lbl_yes")
      } else {
        record.HasSubmitted = this.lang.tra("lbl_no")
      }

      if (record.HasSubmitted == this.lang.tra("lbl_yes") && record.ta_closed_on) {
        record.SubmittedOn = formatDate(new Date(record.ta_closed_on), 'yyyyMMdd', 'en_US')
      } else {
        record.SubmittedOn = ''
      }

      delete record.ta_closed_on;

      // Modify StartedOn
      if (record.StartedOn) {
        record.StartedOn = formatDate(new Date(record.StartedOn), 'yyyyMMdd', 'en_US')
      } else {
        record.StartedOn = ''
      }

      // Modify Result, OSSLTScaleScore and HasReport
      if (record.Result) {
        switch (+record.Result) {
          case OSSLT_OUTCOMES.PENDING: {
            record.Result = this.lang.tra("sa_report_state_0");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }  
          case OSSLT_OUTCOMES.SUCCESSFAUL: {
            record.Result = this.lang.tra("sa_report_state_1");
            record.ScaleScoreDescription = this.lang.tra(`osslt_report_scalescore_1_succ${isVersion2 ? '_v2' : ''}`)
            record.HasReport = this.lang.tra("lbl_yes")
            if(this.showALtScaleScoreInExport(record) && !isVersion2){
              record.OSSLTScaleScore = OTHER_SCALE_SCORE.ALT
              record.ScaleScoreDescription = this.lang.tra("osslt_report_scalescore_3")
            }
            break;
          }
          case OSSLT_OUTCOMES.NOT_YET_SUCCESSFUL: {
            record.Result = this.lang.tra("sa_report_state_2");
            record.ScaleScoreDescription = this.lang.tra(`osslt_report_scalescore_2${isVersion2 ? '_v2' : ''}`)
            record.HasReport = this.lang.tra("lbl_yes")
            if(this.showALtScaleScoreInExport(record) && !isVersion2){
              record.OSSLTScaleScore = OTHER_SCALE_SCORE.ALT
              record.ScaleScoreDescription = this.lang.tra("osslt_report_scalescore_3")
            }
            break;
          }
          case OSSLT_OUTCOMES.ABSENT: {
            record.Result = this.lang.tra("sa_report_state_3");
            record.OSSLTScaleScore = ""
            record.HasReport = isVersion2 ? this.lang.tra("lbl_yes") : this.lang.tra("lbl_no")
            break;
          }
          case OSSLT_OUTCOMES.OSSLC: {
            record.Result = this.lang.tra("sa_report_state_4");
            record.OSSLTScaleScore = ""
            record.HasReport = isVersion2 ? this.lang.tra("lbl_yes") : this.lang.tra("lbl_no")
            break;
          }
          case OSSLT_OUTCOMES.DEFERRED: {
            record.Result = this.lang.tra("sa_report_state_5");
            record.OSSLTScaleScore = ""
            record.HasReport = isVersion2 ? this.lang.tra("lbl_yes") : this.lang.tra("lbl_no")
            break;
          }
          case OSSLT_OUTCOMES.EXEMPTED: {
            record.Result = this.lang.tra("sa_report_state_6");
            record.OSSLTScaleScore = ""
            record.HasReport = isVersion2 ? this.lang.tra("lbl_yes") : this.lang.tra("lbl_no")
            break;
          }
          case OSSLT_OUTCOMES.WITHHELD: {
            record.Result = this.lang.tra("sa_report_state_10");
            record.OSSLTScaleScore = ""
            record.HasReport = isVersion2 ? this.lang.tra("lbl_yes") : this.lang.tra("lbl_no")
            break;
          }
          case OSSLT_OUTCOMES.NOT_SUFFICIENT_EVIDENCE: {
            record.Result = this.lang.tra("sdc_const_osslt_succ_2");
            record.OSSLTScaleScore = 0
            record.ScaleScoreDescription = this.lang.tra(`osslt_report_scalescore_1_na${isVersion2 ? '_v2' : ''}`)
            record.HasReport = isVersion2 ? this.lang.tra("lbl_yes") : this.lang.tra("lbl_no")
            break;
          }          
          default: {
            record.Result = isVersion2 ? this.lang.tra("sa_report_state_0") : this.lang.tra("sa_report_state_2"); // Version 2 change default to pending
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }  
        }
      } else {
        record.Result = isVersion2 ? this.lang.tra("sa_report_state_0") : this.lang.tra("sa_report_state_2"); // Version 2 change default to pending
        record.OSSLTScaleScore = ""
        record.HasReport = this.lang.tra("lbl_no")
      }

      // Modify Note
      if (record.Note === null) {
        record.Note = ''
      }

      records.push(record)
    });

    return records
  }

  /**
   * Show ALT related Scale Score and description for student with isLenear value of 1
   * @param record 
   * @returns {boolean}
   */
  showALtScaleScoreInExport(record):boolean {
    const isLinearReport = record.isLinear == '1'
    const showALTAsScore = isLinearReport && !record.show_isr_alt_score  // if show_isr_alt_score
    return showALTAsScore
  }
}
